import Service from "./service";

export function createService(baseUrl, requestedHeader) {
    // Set the endpoint for this service instance
    const service = new Service(baseUrl, requestedHeader);

    return {
        browse: (offset, limit, filter, sortingList, parentId) =>
            service.findAll(offset, limit, filter, sortingList, parentId),
        getById: (id) => service.findById(id),
        save: (entity) => service.save(entity),
        deleteRequest: (id) => service.deleteById(id),
        // ... other service-specific methods
    };
}
