import {BrowserCacheLocation, LogLevel} from "@azure/msal-browser";
import {permissionHelper} from "@/helpers/permissionHelper";

const disableLoggingLevel = process.env.VUE_APP_MSAL_LOG === 'false';

function updatePermission(map, permission) {
    let permissionLevel = map[permission.permission]
    if (permissionLevel === null || permissionLevel === undefined) {
        if (permission.deny) {
            return
        }
        map[permission.permission] = permission.deny
    } else {
        if (!permission.deny) {
            map[permission.permission] = permission.deny
        } else {
            delete map[permission.permission]
        }
    }
}

const state = {
    account: null,
    user: null,
    token: null,
    tenant: null,
    company: null
}


function _isControlTowerOrPlatformAdmin() {
    return permissionHelper.userHasRoles(['PLATFORM_ADMIN', 'CONTROL_TOWER'])
}


const getters = {
    getAccount(state) {
        return state.account
    },
    getMsalConfig() {
        return {
            auth: {
                clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
                authority: process.env.VUE_APP_AZURE_AUTHORITY,
                knownAuthorities: [process.env.VUE_APP_AZURE_KNOWN_AUTHORITY],
                redirectUri: process.env.VUE_APP_REDIRECT_LOGIN_URI + "/signin",
                postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_LOGOUT_URI,
                validateAuthority: false,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }

                        if (disableLoggingLevel && level !== LogLevel.Error) {
                            return;
                        }

                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                break;
                            case LogLevel.Info:
                                console.info(message);
                                break;
                            case LogLevel.Verbose:
                                console.debug(message);
                                break;
                            case LogLevel.Warning:
                                console.warn(message);
                                break;
                            default:
                                break;
                        }
                    },
                    piiLoggingEnabled: false
                }
            }
        };
    },
    getUser(state) {
        return state.user;
    },
    getToken(state) {
        return state.token;
    },
    getTenant(state) {
        return state.tenant;
    },
    getCompany(state) {
        if (_isControlTowerOrPlatformAdmin()) {
            return null;
        }
        return state.company;
    },
    isUserControlTower(state) {
        if (!state.user) {
            return false
        }
        return permissionHelper.userHasRoles(['CONTROL_TOWER'])
    },
    isUserPlatformAdmin(state) {
        if (!state.user) {
            return false
        }
        return permissionHelper.userHasRoles(['PLATFORM_ADMIN'])
    },
    isControlTowerOrPlatformAdmin() {
        return _isControlTowerOrPlatformAdmin()
    },
    getUserRoles(state) {
        if (!state.user) {
            return []
        }
        return state.user.globalRoles
    },
    getUserPermission(state) {
        let map = {}
        let user = state.user
        let tenant = state.tenant
        if (!user) {
            console.log('no user', state)
            return map
        }
        if (!tenant) {
            console.log('no tenant', state)
            return map
        }

        let permissionForTenant = user.companyAndPermissions.find(
            (permission) => permission.company.id === tenant.id,
        )

        if (permissionForTenant?.groups) {
            for (let groups of permissionForTenant.groups) {
                for (let permission of groups.permissions) {
                    updatePermission(map, permission)
                }
            }
        }
        if (permissionForTenant?.customs) {
            for (let permission of permissionForTenant.customs) {
                updatePermission(map, permission)
            }
        }
        return map
    },
}

const mutations = {
    setAccount(state, account) {
        state.account = account
    },
    setUser(state, user) {
        state.user = user
        if (!user) {
            state.tenant = null
            state.company = null
        } else {
            //set default tenant / Company if they are empty
            if (!state.tenant && user.companyAndPermissions.length > 0) {
                state.tenant = user.companyAndPermissions
                    .map((x) => x.company)
                    .sort((a, b) => a.name.localeCompare(b.name))[0];
            } /*else {
                console.log("setUser Keep Old Tenant", state.tenant)
            }*/
            if (!state.company && user.companies.length > 0) {
                state.company = user.companies
                    .map((x) => x)
                    .sort((a, b) => a.name.localeCompare(b.name))[0];

                //console.log("setUser Company", state.company)
            } /*else {
                console.log("setUser Keep Old Company", state.company)
            }*/
        }
    },
    setToken(state, token) {
        state.token = token
    },
    setTenant(state, tenant) {
        state.tenant = tenant
    },
    setCompany(state, company) {
        state.company = company
    },
    setTenantId(state, tenantId) {
        if (!state.user) {
            state.tenant = null;
            return;
        }
        let tenantChoosen = state.user.companyAndPermissions.find(
            (permission) => permission.company.id === tenantId);
        if (tenantChoosen) {
            state.tenant = tenantChoosen.company;
        } else {
            console.error("Tenant not found", tenantId, state.user.companyAndPermissions)
        }
    },
    setCompanyId(state, companyId) {
        if (!state.user) {
            state.company = null;
            return;
        }
        state.company = state.user.companies.find(
            (company) => company.id === companyId);
    }
}

export const account = {
    namespaced: true,
    state,
    getters,
    mutations,
}
