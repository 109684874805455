import { authHeader } from "@/helpers/auth-header";
import { commons } from "./commons";

export default class Service {
  constructor(baseUrl, requestedHeader) {
    this.baseUrl = baseUrl;
    this.requestedHeader = requestedHeader;
  }

  deleteById(id) {
    const init = {
      method: "DELETE",
      headers: { ...authHeader(), ...this.requestedHeader },
    };

    return fetch(`${this.baseUrl}/${id}`, init).then(
      commons.handleJsonResponse
    );
  }

  findAll(offset, limit, filter, sortingList, parentId) {
    const init = {
      method: "POST",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
        ...this.requestedHeader,
      },
      body: JSON.stringify({
        offset: offset,
        limit: limit,
        filter: filter,
        sortingList: sortingList,
      }),
    };

    const url = parentId
      ? `${this.baseUrl.replace("${parentId}", parentId)}/browse`
      : `${this.baseUrl}/browse`;

    return fetch(url, init).then(commons.handleJsonResponse);
  }

  findById(id) {
    const init = {
      method: "GET",
      headers: { ...authHeader(), ...this.requestedHeader },
    };

    const url = `${this.baseUrl}/${id}`;

    return fetch(url, init).then(commons.handleJsonResponse);
  }

  save(entity) {
    let method = "POST";
    let identifier = null;
    if (entity.id || entity.uuid) {
      method = "PUT";
      identifier = entity.uuid;
      if (entity.id) {
        identifier = entity.id;
      }
    }

    const init = {
      method: method,
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
        ...this.requestedHeader,
      },
      body: JSON.stringify(entity),
    };

    const url = identifier ? `${this.baseUrl}/${identifier}` : this.baseUrl;

    return fetch(url, init).then(commons.handleJsonResponse);
  }
}
