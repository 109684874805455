import _ from 'lodash'
import {mapMutations, mapState} from "vuex";

export default {
    data() {
        return {
            _: _,
        }
    },
    computed: {
        ...mapState('panels', ['expandAll']),
    },
    methods: {
        ...mapMutations({setSnackbarMessage: "snackbar/setSnackbarMessage"}),
        ...mapMutations({setExpandAll: 'panels/setExpandAll'}),
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
                reader.onerror = reject;
            })
        }
    }
}
