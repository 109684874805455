import {authHeader} from '@/helpers/auth-header'
import {commons} from './commons'
import { createService } from './serviceFactory'

const resourceUrl = process.env.VUE_APP_BACKEND_URL + `/validationrules`;

export const validationService = createService(resourceUrl);

validationService.getCustomValidation = (type, masterCompanyId) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    // return fetch(`/assets/po_example.json`, requestOptions).then(commons.handleStringResponse)
    
    const search = isNaN(masterCompanyId) ? "" : `?${new URLSearchParams({ masterCompanyId })}`;
    return fetch(
        `${resourceUrl}/byType/${type}${search}`,
        requestOptions
    ).then(commons.handleJsonResponse)
    .then((res) => res?.rule);
}
