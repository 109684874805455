export class FieldValue {
	field: string;
	value: any;
    parent?: FieldValue;

	constructor(field, value, parent?: FieldValue) {
		this.field = field;
		this.value = value;
        this.parent = parent;
	}

    private childField(child: string|number) {
        if (this.field) {
            if (typeof child == "number") {
                return `${this.field}[${child}]`;
            } else {
                return `${this.field}.${child}`;
            }
        } else {
            if (typeof child == "number") {
                return `[${child}]`;
            } else {
                return `${child ?? ""}`;
            }
        }
    }

    getChild(fieldToken: string, defaultValue?: any): FieldValue|FieldValue[] {
        if (!fieldToken) {
            return this;
        } else if (this.value == null || this.value == undefined) {
            return new FieldValue(this.childField(fieldToken), defaultValue, this);
        }

        // get value from map or collection
        if (fieldToken == "*") {
            // do for every token
            if (Array.isArray(this.value)) {
                return this.value.map((value, index) => new FieldValue(this.childField(index), value, this));
            } else if (typeof this.value == 'object') {
                return Object.entries(this.value).map(([key, value]) => new FieldValue(this.childField(key), value, this));
            } else {
                return new FieldValue(fieldToken, null, this);
            }
        } else {
            return new FieldValue(this.childField(fieldToken), this.value[fieldToken] ?? defaultValue, this);
        }
    }
}

export function splitFieldName(fieldName = "") {
    const tokens: string[] = []
	for (const token of fieldName.split(".")) {
		for (const matchGroup of (token.match(/(\[.*?])|([^[\]]+)/g) || [])) {
			if (matchGroup == "[]") {
				tokens.push("*");
			} else if (matchGroup.startsWith("[") && matchGroup.endsWith("]")) {
				tokens.push(matchGroup.slice(1, -1));
			} else {
				tokens.push(matchGroup);
			}
		}
	}
    return tokens;
}

export function getFieldValues(root: any, fieldName: string, defaultValue?: any): FieldValue|FieldValue[] {
	if (!fieldName) return new FieldValue("", root);
	
	const tokens = splitFieldName(fieldName);

	let result: FieldValue|FieldValue[] = new FieldValue("", root);

	for (const token of tokens) {
		if (Array.isArray(result)) {
            const nextResult: FieldValue[] = [];
            for (const fieldValue of result) {
                const res = fieldValue.getChild(token, defaultValue);
                if (Array.isArray(res)) {
                    nextResult.push(...res);
                } else {
                    nextResult.push(res);
                }
            }
            result = nextResult;
		} else {
            result = result.getChild(token, defaultValue);
		}
	}

    return result;
}