import { ValidationContext } from "@/validationEngine/models/ValidationContext";
import { AbstractValidationRule } from "./AbstractValidationRule";
import { ValidationError } from "@/validationEngine/models/ValidationError";
import { stringifyValue } from "@/validationEngine/utils/StringUtils";

enum Type {
    IF = "IF"
}

export class ConditionRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { condition: AbstractValidationRule, onTrue: any, onFalse: any }) {
        return new ConditionRule(obj['condition'], obj['onTrue'], obj['onFalse']);
    }

    type: Type = Type.IF;
    condition: AbstractValidationRule;
    onTrue: any;
    onFalse: any;

    constructor(condition: AbstractValidationRule, onTrue: any, onFalse: any) {
        super();
        this.condition = condition;
        this.onTrue = onTrue;
        this.onFalse = onFalse;
    }

    public execute(context: ValidationContext): boolean {
		if (this.condition.executeAndCatch(context)) {
            return this.onTrue instanceof AbstractValidationRule ? (this.onTrue as AbstractValidationRule).execute(context) : true;
		} else {
            return this.onFalse instanceof AbstractValidationRule ? (this.onFalse as AbstractValidationRule).execute(context) : true;
		}
	}

    public validate(context: ValidationContext): ValidationError[] {
		if (this.condition.executeAndCatch(context)) {
            return this.onTrue instanceof AbstractValidationRule ? (this.onTrue as AbstractValidationRule).validate(context) : [];
		} else {
            return this.onFalse instanceof AbstractValidationRule ? (this.onFalse as AbstractValidationRule).validate(context) : [];
		}
	}

    toString(): string {
        const args: any[] = [];
        args.push(this.condition);
        args.push(this.onTrue);
        args.push(this.onFalse);
        return `${this.type}(${args.map(stringifyValue).join(', ')})`;
    }
}

