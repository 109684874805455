const getDefaultDialog = () => {
    return {
        show: false,
        title: undefined,
        text: undefined,
        submit: undefined,
        cancel: undefined,
    }
}

const state = {
    isEditing: false,
    dialog: getDefaultDialog()
}

const getters = {
    getIsEditing(state) {
        return state.isEditing
    },
}

const mutations = {
    setEditing(state, isEditing) {
        state.isEditing = isEditing
    },
    setConfirmExitDialog(state, {title, text, submit, cancel}) {
        state.dialog.title = title
        state.dialog.text = text
        state.dialog.submit = submit
        state.dialog.cancel = cancel
        state.dialog.show = true
    },
    resetConfirmExitDialog(state) {
        state.dialog = getDefaultDialog()
    },
}

export const edit = {
    namespaced: true,
    state,
    getters,
    mutations,
}
