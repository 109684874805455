<template>
  <CustomFieldWrapper
    class="div-custom-fields"
    :visible="isVisible"
    :vCol="vCol"
  >
    <span class="span-label" v-if="$attrs.label">{{ $attrs.label }}</span>
    <v-text-field
      :maxlength="$attrs.type === 'number' ? 15 : 255"
      v-model="fieldModel"
      v-bind="$attrs"
      :label="null"
      :readonly="isReadonly"
      :rules="fieldRules"
      @update:modelValue="updateModelValue"
      @input="$emit('input', $event)"
      @keydown="preventValues($event)"
    >
      <template v-for="slot in parentSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="{...scope}"/>
      </template>
    </v-text-field>
  </CustomFieldWrapper>
</template>

<script>
import {computed} from "vue";
import { CustomFieldMixin } from '@/mixins/customField.mixin'
import { CustomFieldWrapperMixin } from '@/components/fields/CustomFieldWrapper.vue'

export default {
  name: "CustomVTextField",
  inheritAttrs: false,
  mixins: [CustomFieldMixin, CustomFieldWrapperMixin],
  setup(props, ctx) {
    const parentSlots = computed(() => Object.keys(ctx.slots))
    return { parentSlots };
  },
  methods: {
    /** Prevent some values from being inputted in the input field
     *
     * @param event
     */
    preventValues(event) {
      if (this.$attrs.type !== "number") {
        this.$emit("keydown", event);
        return;
      }
      if (event.key === "e" || event.key === "E" || event.key === "+") {
        event.preventDefault();
        return;
      }
      if (event.key === "-") {
        if (this.$attrs.min != null) {
          if (this.$attrs.min >= 0) {
            event.preventDefault();
            return;
          }
        }
        if (
            (this.$attrs.modelValue != null && (('' + this.$attrs.modelValue).includes("-") || ('' + this.$attrs.modelValue).length > 0))
        ) {
          event.preventDefault();
          return;
        }
      }
      this.$emit("keydown", event);
    },
    cleanUpStep(value) {
      if (!this.$attrs.step) {
        return value;
      }
      const step = "" + this.$attrs.step;
      if (!step.includes(".")) {
        if (("" + value).length < 6) {
          return value;
        }
        value = parseInt(("" + value).slice(0, 6));
      } else if (step.includes(".") && ("" + value).includes(".")) {
        //step can be 0.001, i need to parse by the decimal number present
        let stepDecimals = step.split(".")[1].length;
        let valueParts = ("" + value).split(".");
        let valueDecimals = valueParts[1].length;
        if (valueDecimals > stepDecimals) {
          //always floor the value
          //value to str then get the right part
          value = valueParts[0] + "." + valueParts[1].slice(0, stepDecimals);
        }
      }
      return value;
    },
    cleanUpNumber(value) {
      if (this.$attrs.type !== "number" || Number.isNaN(value)) {
        return value;
      }

      value = this.cleanUpStep(value);

      if (!(value === null || value === undefined || value === "")) {
        value = this.cleanUpMin(value);

        value = this.cleanUpMax(value);
      }

      return value;
    },
    updateModelValue(value) {
      value = this.cleanUpNumber(value);
      if (this.$attrs.type === "number" && Number.isNaN(value)) {
        return;
      }
      this.$emit("update:modelValue", value);
    },
    cleanUpMin(value) {
      if (this.$attrs.min == null) {
        return value;
      }
      if (value < this.$attrs.min) {
        value = this.$attrs.min;
      }
      return value;
    },
    cleanUpMax(value) {
      if (this.$attrs.max == null) {
        return value;
      }
      if (value > this.$attrs.max) {
        value = this.$attrs.max;
      }
      return value;
    },
  }
};
</script>