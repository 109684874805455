<template>
  <custom-v-text-field
      v-bind="$attrs"
      :bg-color="background"
      :label="rowEdit ? '' : column.title"
      :modelValue="item[column.key]"
      :readonly="readonly"
      :rules="column.rules"
      hide-spin-buttons
      min="0"
      step="0.00001"
      type="number"
      variant="outlined"
      validate-on="blur"
  >
    <template v-slot:append-inner>
      <span>{{ sizeUnit }}</span>
    </template>
  </custom-v-text-field>
</template>
<script>
import sizeUnitEnum from "@/enums/sizeUnitEnum";

export default {
  name: 'EditableWeightField',
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rowEdit: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    sizeUnit() {
      if (!this.item?.sizeUnit) {
        return null;
      }
      return sizeUnitEnum.find((item) => item.value === this.item.sizeUnit)
          ?.abbreviation;
    },
    background() {
      return "white";
    },
  }
}
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: white;
}

.active .v-field--variant-solo-filled :deep(.v-field__input) {
  color: white;
}
</style>
