import { ValidationContext } from "@/validationEngine/models/ValidationContext";
import { AbstractValidationRule } from "./AbstractValidationRule";
import { stringifyValue } from "@/validationEngine/utils/StringUtils";
import { ValidationError } from "@/validationEngine/models/ValidationError";
import { FieldValue, getFieldValues } from "@/validationEngine/helpers/FieldValueHelper";

enum Type {
    FIELD_VALIDATION = "FIELD_VALIDATION"
}

export class FieldValidationRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { type: Type, rule: AbstractValidationRule, values: any[] }) {
        return new FieldValidationRule(obj['type'], obj['rule'], obj['values']);
    }

    type: Type = Type.FIELD_VALIDATION;
    fieldName: string;
    rule: AbstractValidationRule;
	defaultValue: any;

    constructor(fieldName: string, rule: AbstractValidationRule, defaultValue?: any) {
        super();
        this.fieldName = fieldName;
        this.rule = rule;
        this.defaultValue = defaultValue;
    }

	private getFieldValues(context: ValidationContext): FieldValue|FieldValue[] {
		if (!this.fieldName) { // use "_" insead of ""?
			return getFieldValues(context.fieldValue, "", this.defaultValue);
		} else if (this.fieldName.startsWith("$.")) {
			return getFieldValues(context.root, this.fieldName.substring(2), this.defaultValue);
		} else if (this.fieldName == "$") {
			return getFieldValues(context.root, "", this.defaultValue);
		} else {
			return getFieldValues(context.fieldValue, this.fieldName, this.defaultValue);
		}
	}

	public execute(context: ValidationContext): boolean {
		if (this.rule == null) return true;
		const fieldValues = this.getFieldValues(context);
		if (Array.isArray(fieldValues)) {
			for (const fieldValue of fieldValues) {
				if (!this.rule.executeAndCatch(context.createChild(fieldValue.value, fieldValue.field))) return false;
			}
			return true;
		} else {
			return this.rule.execute(context.createChild(fieldValues.value, fieldValues.field));
		}
	}

    public validate(context: ValidationContext): ValidationError[] {
		if (this.rule == null) return [];
		const fieldValues = this.getFieldValues(context);
		if (Array.isArray(fieldValues)) {
			const errors: ValidationError[] = [];
			for (const fieldValue of fieldValues) {
				errors.push(...this.rule.validate(context.createChild(fieldValue.value, fieldValue.field)));
			}
			return errors;
		} else {
			return this.rule.validate(context.createChild(fieldValues.value, fieldValues.field));
		}
	}

	toString() {
        const args = [
			stringifyValue(this.fieldName),
			stringifyValue(this.rule)
		];
        if (this.defaultValue !== undefined) args.push(stringifyValue(this.defaultValue));
        return `${this.type}(${args.join(", ")})`;
    }
}
