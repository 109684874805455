export class ValidationError {
    static fromField(field: string, message?: string) {
        return new ValidationError(message, field, undefined);
    }
    static fromValue(value: any, message?: string) {
        return new ValidationError(message, undefined, JSON.stringify(value));
    }

    field?: string;
    value?: string;
    message: string = "";

    constructor(message = "", field?: string, value?: string, ) {
        this.field = field;
        this.value = value;
        this.message = message;
    }

    getTarget(): string {
        if (this.field !== undefined) {
            return `Field "${this.field}"`;
        } else if (this.value !== undefined) {
            return `Value ${this.value}`;
        } else {
            return "";
        }
    }

    // getMessage(): string {
    //     if (this.message === null) {
    //         return this.description === null ? "" : this.description;
    //     } else {
    //         return `${this.message.getCode()} - ${StringUtils.isEmpty(this.description) ? this.errorMessage.getMessage() : this.description}`;
    //     }
    // }

    setMessage(message: string) {
        this.message = message;
        return this;
    }

    toString(): string {
        let target = this.getTarget();
        if (target === null) {
            target = "";
        } else {
            target += " ";
        }
        return target + this.message;
    }
}

