<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6134 8.23366C13.2667 5.10699 10.7334 3.16699 8.00004 3.16699C5.26671 3.16699 2.73338 5.10699 1.38671 8.23366C1.35 8.31777 1.33105 8.40855 1.33105 8.50033C1.33105 8.5921 1.35 8.68288 1.38671 8.76699C2.73338 11.8937 5.26671 13.8337 8.00004 13.8337C10.7334 13.8337 13.2667 11.8937 14.6134 8.76699C14.6501 8.68288 14.669 8.5921 14.669 8.50033C14.669 8.40855 14.6501 8.31777 14.6134 8.23366ZM8.00004 12.5003C5.88671 12.5003 3.88671 10.9737 2.73338 8.50033C3.88671 6.02699 5.88671 4.50033 8.00004 4.50033C10.1134 4.50033 12.1134 6.02699 13.2667 8.50033C12.1134 10.9737 10.1134 12.5003 8.00004 12.5003ZM8.00004 5.83366C7.47263 5.83366 6.95706 5.99006 6.51852 6.28307C6.07999 6.57609 5.7382 6.99257 5.53637 7.47984C5.33453 7.96711 5.28172 8.50328 5.38462 9.02057C5.48751 9.53785 5.74149 10.013 6.11443 10.3859C6.48737 10.7589 6.96252 11.0129 7.4798 11.1158C7.99709 11.2186 8.53326 11.1658 9.02053 10.964C9.5078 10.7622 9.92428 10.4204 10.2173 9.98185C10.5103 9.54331 10.6667 9.02774 10.6667 8.50033C10.6667 7.79308 10.3858 7.1148 9.88566 6.61471C9.38557 6.11461 8.70729 5.83366 8.00004 5.83366ZM8.00004 9.83366C7.73634 9.83366 7.47855 9.75546 7.25928 9.60895C7.04002 9.46244 6.86912 9.2542 6.76821 9.01057C6.66729 8.76693 6.64088 8.49885 6.69233 8.2402C6.74378 7.98156 6.87077 7.74399 7.05724 7.55752C7.24371 7.37105 7.48128 7.24406 7.73992 7.19261C7.99857 7.14116 8.26665 7.16757 8.51029 7.26849C8.75392 7.3694 8.96216 7.5403 9.10867 7.75956C9.25518 7.97883 9.33338 8.23662 9.33338 8.50033C9.33338 8.85395 9.1929 9.19309 8.94285 9.44313C8.6928 9.69318 8.35367 9.83366 8.00004 9.83366Z"
      fill="currentcolor"
    />
  </svg>
</template>
