<template>
  <v-app>
    <router-view :key="$route.path" />
    <ConfirmExitDialog />
    <v-overlay
      :model-value="myOverlay"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular color="primary" indeterminate size="64">
        <template v-slot:default v-if="getProgress || getProgress == 0">
          {{ getProgress }} %
        </template>
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({ setSnackbarMessage: "snackbar/setSnackbarMessage" }),
  },
  computed: {
    ...mapGetters({
      myOverlay: "snackbar/getOverlay",
      getUser: "account/getUser",
      getProgress: "snackbar/getProgress",
    }),
  },
  watch: {
    '$store.overlay': {
      handler() {
        console.log("overlay changed")
        this.myOverlay = this.$store.state.overlay ?? false
      },
      deep: true,
    },
  },
}
</script>

<style>
@import "@/App.scss";

</style>
