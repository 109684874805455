<template>
  <component
    :is="getComponent"
    :convertFunction="item.convertFunction"
    :extraParameters="item.extraParameters"
    :extractValue="alwaysReturnObject ? (item) => item :  item.extractValue"
    :extractValues="alwaysReturnObject ? (items) =>items  : item.extractValues"
    :hideDetails="hideDetails || item.hideDetails"
    :label="getLabel()"
    :placeholder="item.placeholder"
    :maxlength="item.maxLength ?? 254"
    :preselection="
      item.extractObject
        ? item.extractObject(preselectedValues)
        : preselectedValues
        ? preselectedValues[item.key]
        : undefined
    "
    :readonly="!isEditable || getFieldDisabled"
    :rules="item.rules"
    :searchFunction="item.searchFunction"
    :searchKey="item.searchKey ?? item.key"
    :setSelectedValues="item.setSelectedValues"
    :step="item.step ?? 1"
    :max="item.max"
    :column="item" :min="item.min"
    :typeOf="item.typeOf"
    :updated="item"
    :variant="item.variant ?? variant"
    @setSelectedValues="setSelectedValues"
    :clearable="item.clearable"
  />
</template>

<script>
import DynamicInputsMixin from "@/mixins/dynamicInputs.mixin";

export default {
  name: "ComponentSelector",
  mixins: [DynamicInputsMixin],
  emits: ["setSelectedValues"],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    fieldsEnabling: {
      type: Object,
      default: () => {},
    },
    preselectedValues: {
      type: Object,
      default: () => {},
    },
    cleared: {
      type: Number,
      default: undefined,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    alwaysReturnObject: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSelectedValues(key, value) {
      this.$emit("setSelectedValues", key, value, this.item);
    },
    getLabel() {
      if (this.variant !== "underlined") return this.item.title;
      return "";
    },
  },
  computed: {
    getFieldDisabled() {
      if (!this.fieldsEnabling) return false;
      if (this.fieldsEnabling[this.item.key] === undefined) return false;
      return this.fieldsEnabling[this.item.key] === false;
    },
    getComponent() {
      if (this.item.component) return this.item.component;

      switch (this.item.typeOf) {
        default:
        case "Number":
        case "Password":
          return "DynamicTextField";
        case "String":
          if (this.item.area) return "DynamicTextArea";
          return "DynamicTextField";
        case "Boolean":
          return "DynamicCheckbox";
        case "Date":
          return "DynamicDatePicker";
        case "DateTime":
          return "DynamicDateTimePicker";
        case "Select":
          if (this.item.multiple) return "DynamicSelectMultiple";
          return "DynamicSelect";
        case "EditWeight":
          return "EditableWeightField";
        case "EditSize":
          return "EditableSizeField";
      }
    },
  },
  created() {},
};
</script>
