import _ from "lodash";

export const activeFilters = {
  namespaced: true,

  state: {
    purchaseOrdersActiveFilters: null,
    shipmentRequestsActiveFilters: null,
    shipmentOrdersActiveFilters: null,
    shipmentsActiveFilters: null,
  },
  getters: {
    getPurchaseOrdersActiveFilters(state) {
      return state.purchaseOrdersActiveFilters;
    }, // -> getters['activeFilters/getPurchaseOrdersActiveFilters']
    getShipmentRequestsActiveFilters(state) {
      return state.shipmentRequestsActiveFilters;
    }, // -> getters['activeFilters/getShipmentRequestsActiveFilters']
    getShipmentOrdersActiveFilters(state) {
      return state.shipmentOrdersActiveFilters;
    }, // -> getters['activeFilters/getShipmentOrdersActiveFilters']
    getShipmentsActiveFilters(state) {
      return state.shipmentsActiveFilters;
    }, // -> getters['activeFilters/getShipmentsActiveFilters']
  },
  mutations: {
    storePurchaseOrdersFilters(state, filters) {
      state.purchaseOrdersActiveFilters = _.cloneDeep(filters);
    },
    storeShipmentRequestsFilters(state, filters) {
      state.shipmentRequestsActiveFilters = _.cloneDeep(filters);
    },
    storeShipmentOrdersActiveFilters(state, filters) {
      state.shipmentOrdersActiveFilters = _.cloneDeep(filters);
    },
    storeShipmentsActiveFilters(state, filters) {
      state.shipmentsActiveFilters = _.cloneDeep(filters);
    },
    resetState(state) {
      console.log('resetState');
      
      state.purchaseOrdersActiveFilters = null;
      state.shipmentRequestsActiveFilters = null;
      state.shipmentOrdersActiveFilters = null;
      state.shipmentsActiveFilters = null;
    },
  },
};
