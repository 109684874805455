import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "../value/AbstractValueRule";
import { AbstractValidationRule } from "./AbstractValidationRule";

enum Type {
    PRESET = "PRESET"
}

export class PresetValidationRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { preset: string, value: any }) {
        return new PresetValidationRule(obj['preset'], obj['value']);
    }

    type: Type = Type.PRESET;
    preset: string;
    value: any;

    constructor(preset: string, value: any) {
        super();
        this.preset = preset;
        this.value = value;
    }

    private getRule(context: ValidationContext): AbstractValidationRule {
        const rule: AbstractValidationRule = context.presets[this.preset];
        if (!rule) throw new Error("Unknown rule preset \"" + this.preset + "\"");
        return  rule;
    }

	public execute(context: ValidationContext): boolean {
        switch (this.type) {
            case Type.PRESET: {
                const rule: AbstractValidationRule = this.getRule(context);
                return rule.execute(context.createCopyWithValueAndFieldName(
                    this.getCurrentValue(this.value, context),
                    this.getCurrentFieldName(this.value, context)
                ));
            }
            default:
                return false;
        }
	}

    public validate(context: ValidationContext): ValidationError[] {
        const errors: ValidationError[] = [];

        try {
            switch (this.type) {
                case Type.PRESET: {
                    const rule: AbstractValidationRule = this.getRule(context);
                    errors.push(...rule.validate(context.createCopyWithValueAndFieldName(
                        this.getCurrentValue(this.value, context),
                        this.getCurrentFieldName(this.value, context)
                    )));
                    break;
                } 
                default:
                    errors.push(new ValidationError("Unknown preset type " + this.type));
            }
        } catch(e: any) {
            // console.error(e);
            errors.push(new ValidationError(e.message));
        }
        return errors;
    }

    public toString() {
        return `${this.type}(${this.preset}, ${stringifyValue(this.value)})"`;
    }
}
