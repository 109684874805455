<template>
  <CustomVSelect
    v-model="selectedValues"
    :class="{ active: isActive }"
    :bg-color="background"
    :clearable="clearable && !readonly"
    :item-title="(item) => convertFunction(item)"
    :item-value="(item) => item"
    :items="loadedItems"
    :label="labelSlot === 'label' ? label : undefined"
    :placeholder="placeholder"
    :prefix="labelSlot === 'prefix' ? prefix : undefined"
    :readonly="readonly"
    :rules="rules"
    :variant="variant"
    density="compact"
    flat
    persistent-placeholder
    style="overflow:hidden;white-space: nowrap; text-overflow: ellipsis;"
  >
    <template v-slot:no-data>
      <div class="d-flex justify-center">
        <span class="text-caption">No results were found</span>
      </div>
    </template>
    <template v-slot:selection="{ item, index }">
      <span v-if="isActorAutocomplete" :style="[$attrs.style,
      'text-overflow: ellipsis', 'overflow:hidden', 'white-space: nowrap']"
            :title="convertFunction(item.value)">
        <span style="color: #8e98a8">{{ platformCode }} </span>
        <span :style="[$attrs.style]" class="font-weight-bold">
          - {{
            convertFunction(item.value)
          }}</span>
      </span>
      <span v-else-if="index < 1" :style="[$attrs.style, 'text-overflow: ellipsis',
      'overflow:hidden', 'white-space: nowrap']" class="selection font-weight-medium">
        {{ convertFunction(item.value) }}
      </span>
      <span
        v-if="index === 1"
        class="selection text-grey text-caption align-self-center ml-1"
        :style="[$attrs.style, 'text-overflow: ellipsis',
      'overflow:hidden', 'white-space: nowrap']"
      >
        (+{{ selectedValues.length - 1 }} others)
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item class="pa-0" density="compact">
        <custom-v-text-field
          v-model="searchText"
          append-inner-icon="mdi-magnify"
          class="pa-2"
          density="compact"
          label="Search"
          variant="outlined"
        />
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:append-item>
      <v-divider />
      <v-list-item class="pa-3 pb-1" density="compact">
        <v-btn-primary color="error" variant="text" @click="clearSelection"
          >Reset</v-btn-primary
        >
      </v-list-item>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </CustomVSelect>
</template>

<script>
export default {
  name: "DynamicSelect",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchKey: {
      type: String,
      required: true,
    },
    preselection: {
      type: [Object, String, Boolean],
      required: false,
      default: null,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    convertFunction: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    extractValue: {
      type: Function,
      default: (item) => item,
    },
    setSelectedValues: {
      type: Function,
      required: false,
      default: async (loadedItems, selectedItems) => {
        return selectedItems;
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelSlot: {
      type: String,
      default: "label",
    },
    isActorAutocomplete: {
      type: Boolean,
      default: false,
    },
    platformCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchText: "",
      selectedValues: undefined,
      loadedItems: [],
      totalItems: 0,
      offset: 0,
      limit: 15,
    };
  },
  computed: {
    prefix() {
      return this.isActive ? `${this.label}: ` : this.label;
    },
    background() {
      if (this.variant !== "solo-filled") return "white";
      return this.isActive ? "primary" : "grey-lighten-5";
    },
    isActive() {
      return this.selectedValues !== undefined;
    },
  },
  watch: {
    selectedValues: {
      handler() {
        // console.log("selectedValues", this.selectedValues);
        this.setSelection();
      },
      deep: true,
    },
    cleared() {
      if (this.clearable) this.selectedValues = undefined;
    },
    searchText() {
      if (this._searchTimeoutId) {
        clearTimeout(this._searchTimeoutId);
      }

      this._searchTimeoutId = setTimeout(() => {
        this.searchFunction(this.searchText, this.offset, this.limit)
            .then((response) => {
              if (response.resultList) {
                this.loadedItems = response.resultList;
                this.totalItems = response.totalCount;
              } else {
                this.loadedItems = response;
                this.totalItems = response?.length;
              }
            })
            .catch((e) => {
              console.error(e);
              this.setSnackbarMessage({
                text: "Error while loading items " + e,
                color: "error",
              });
            });
      }, 500);
    },
    preselection: {
      handler() {
        this.loadPreselectedItem();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    executeRemoteSearch() {
      return this.searchFunction(this.searchText, this.offset, this.limit)
        .then((response) => {
          if (this.offset === 0) {
            if (response.resultList) this.loadedItems = response.resultList;
            else this.loadedItems = response;
          } else {
            if (response.resultList)
              this.loadedItems.push(...response.resultList);
            else this.loadedItems.push(...response);
          }
          this.totalItems = response.totalCount
            ? response.totalCount
            : response.length;
        })
        .catch((e) => {
          console.error(e);
          this.setSnackbarMessage({
            text: "Error while loading items " + e,
            color: "error",
          });
        });
    },
    // Single selection
    setSelection() {
      let value = this.extractValue(this.selectedValues);
      this.$emit("setSelectedValues", this.searchKey, value);
    },
    clearSelection() {
      this.selectedValues = undefined;
      this.searchText = "";
    },
    loadPreselectedItem() {
      if (this.preselection === undefined || this.preselection === null)
        return (this.selectedValues = undefined);

      this.setSelectedValues(this.loadedItems, this.preselection)
        .then((r) => {
          if (!r && this.loadedItems.length) {
            this.selectedValues = this.preselection;
          } else if (r instanceof Array && r.length == 1)
            this.selectedValues = r[0];
          else this.selectedValues = r;
          return this.selectedValues;
        })
        .then((selected) => {
          if (!selected) return;
          if (
            !this.loadedItems.some((item) => {
              return item === selected;
            })
          )
            this.loadedItems.push(selected);
        });
    },
  },
  mounted() {
    this.executeRemoteSearch().then(() => this.loadPreselectedItem());
  },
};
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: black;
}

.v-select .v-field--dirty .v-select__selection:nth-child(n + 3) {
  display: none !important;
}
</style>
