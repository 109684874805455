<template>
  <CustomFieldWrapper
    class="div-custom-fields"
    :visible="isVisible"
    :vCol="vCol"
  >
    <span v-if="$attrs.label" class="span-label">{{ $attrs.label }}</span>
    <v-textarea
      v-model="fieldModel"
      v-bind="$attrs"
      :label="null"
      :readonly="isReadonly"
      :rules="fieldRules"
    >
      <template v-for="slot in parentSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="{...scope}"/>
      </template>
    </v-textarea>
  </CustomFieldWrapper>
</template>

<script>
import {computed} from "vue";
import { CustomFieldMixin } from '@/mixins/customField.mixin'
import { CustomFieldWrapperMixin } from '@/components/fields/CustomFieldWrapper.vue'

export default {
  name: "CustomVTextArea",
  inheritAttrs: false,
  mixins: [CustomFieldMixin, CustomFieldWrapperMixin],
  setup(props, ctx) {
    const parentSlots = computed(() => Object.keys(ctx.slots))
    return {parentSlots};
  },
};
</script>

<style scoped>
.v-input :deep(.v-input__control) {
  height: unset !important;
}

.v-textarea :deep(.v-field__input) {
  -webkit-mask-image: unset !important;
  mask-image: unset !important;
}
</style>