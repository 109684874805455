<template>
  <v-switch
      v-model="value"
      :false-value="false"
      :label="label"
      :readonly="readonly"
      :rules="rules"

      :true-value="true"
      class="mx-1"
      density="compact"
      inset
  />
</template>

<script>
export default {
  name: "DynamicSwitch",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: true,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    preselection: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: undefined,
      isFocused: false,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
    cleared() {
      this.value = undefined;
    },
    preselection() {
      this.loadPreselectedItem();
    },
  },
  methods: {
    loadPreselectedItem() {
      if (this.preselection == undefined || this.preselection == null) return;
      this.value = this.preselection;
    },
    setValue(value) {
      this.$emit("setSelectedValues", this.searchKey, value);
    },
  },
  mounted() {
    this.loadPreselectedItem();
  },
};
</script>

<style scoped>
.v-switch :deep(label) {
  pointer-events: none;
}
</style>
