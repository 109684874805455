import store from "@/store/index";


function userHasPermission(permission) {
    if (!store.getters["account/getUser"]) {
        return false
    }
    let mapOfPermission = store.getters['account/getUserPermission'];
    if (!mapOfPermission) {
        return false
    }

    //False it means that the user has the permission and it is not denied
    return mapOfPermission[permission] === false;
}

function userHasPermissions(permissions) {
    for (let permission of permissions) {
        if (userHasPermission(permission)) {
            return true;
        }
    }
    return false;
}

function userHasRole(role) {
    if (!store.getters["account/getUser"]) {
        return false
    }
    let roles = store.getters["account/getUserRoles"];

    if (!roles) {
        return false
    }

    return roles.includes(role);
}

function userHasRoles(roles) {
    for (let role of roles) {
        if (userHasRole(role)) {
            return true;
        }
    }
    return false;
}

export const permissionHelper = {
    userHasPermission,
    userHasPermissions,
    userHasRoles
}