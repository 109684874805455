import { ValidationContext } from "../../models/ValidationContext";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "./AbstractValueRule";

enum Type {
    ABSOLUTE = "ABSOLUTE",
    NOT = "NOT"
}

export class ValueTransformationRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { type: Type, value: any }) {
        return new ValueTransformationRule(obj['type'], obj['value']);
    }

    type: Type;
    value: any;
    
    constructor(type: Type, value: any) {
        super();
        this.type = type;
        this.value = value;
    }

    public getFieldName(context: ValidationContext): string|undefined {
        return this.value instanceof AbstractValueRule ? this.value.getFieldName(context) : undefined;
    }

    public getValue(context: ValidationContext): any {
        const currentValue = this.value instanceof AbstractValueRule ? this.value.getValue(context) : this.value;
        switch (this.type) {
            case Type.ABSOLUTE:
                return Math.abs(Number(currentValue));
            default:
                // throw unknown type?
                return currentValue;
        }
    }

    public toString(): string {
        return `${this.type}(${stringifyValue(this.value)})`;
    }
}
