import { AbstractValueRule } from "./AbstractValueRule";
import { ValidationContext } from "../../models/ValidationContext";
import { stringifyValue } from "../../utils/StringUtils";

enum Type {
    ARRAY = "ARRAY",
    // Numerals
    SUM = "SUM",
    SUBTRACT = "SUBTRACT",
    DIVIDE = "DIVIDE",
    MULTIPLY = "MULTIPLY",
    MIN = "MIN",
    MAX = "MAX"
}

export class ValueAggregationRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { type: Type, values: any[] }) {
        return new ValueAggregationRule(obj['type'], obj['values']);
    }

    type: Type;
    values: any[];
    
    constructor(type: Type, values: any[]) {
        super();
        this.type = type;
        this.values = values;
    }

    public getFieldName(context: ValidationContext): string|undefined {
        return undefined;
    }

    private getValues(context: ValidationContext): any[] {
        return this.values.map(value => (value instanceof AbstractValueRule) ? value.getValue(context) : value);
    }

    private getNumerals(context: ValidationContext): number[] {
        const numerals: number[] = [];
        for (const value of this.getValues(context)) {
            numerals.push(Number(value));
        }
        return numerals;
    }

    public getValue(context: ValidationContext): any {
        if (!this.values) return undefined;
        switch (this.type) {
            case Type.ARRAY:
                return this.getValues(context);
            // Numerals
            case Type.SUM:
                return this.getNumerals(context).reduce((total, value) => total == undefined ? value : (total + value));
            case Type.SUBTRACT:
                return this.getNumerals(context).reduce((total, value) => total == undefined ? value : (total - value));
            case Type.DIVIDE:
                return this.getNumerals(context).reduce((total, value) => total == undefined ? value : (total / value));
            case Type.MULTIPLY:
                return this.getNumerals(context).reduce((total, value) => total == undefined ? value : (total * value));
            case Type.MIN:
                return Math.min(...this.getNumerals(context));
            case Type.MAX:
                return Math.max(...this.getNumerals(context));
            default:
                // throw unknown type?
                return undefined;
        }
    }
   
    public toString() {
        return `${this.type}(${this.values.map(stringifyValue).join(", ")})`;
    }
}
