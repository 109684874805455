<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <custom-v-text-field
          v-model="formattedDate"
          :bg-color="background"
          :class="{ active: isActive }"
          :clearable="!readonly"
          :label="labelSlot == 'label' ? label : undefined"
          :prefix="labelSlot == 'prefix' ? prefix : undefined"
          :readonly="readonly"
          :required="mandatory"

          :rules="rules"
          :variant="variant"
          clear-icon="custom:close"
          density="compact"
          flat
          v-bind="props"
          @click:clear="localDateTime = undefined"
      >
        <template v-slot:append-inner>
          <v-icon color="primary-darken">mdi-calendar</v-icon>
        </template>
      </custom-v-text-field>
    </template>
    <VDatePicker v-if="!readonly" v-model="localDateTime" class="mt-1" mode="dateTime"/>
  </v-menu>
</template>

<script>
import moment from 'moment'
import {dateUtils} from "@/helpers/dateUtils";

export default {
  name: 'DateTimePicker',
  emits: ['setDate'],
  props: {
    date: {
      type: [Date, String, Object],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'solo-filled'
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelSlot: {
      type: String,
      default: 'label',
    },
  },
  computed: {
    localDateTime: {
      set(localDate) {
        this.setDate(localDate)
      },
      get() {
        return this.convertDate(this.date)
      },
    },
    formattedDate() {
      return !this.localDateTime ? null : dateUtils.convertToLocalDateTime(moment(this.localDateTime))
    },
    prefix() {
      return this.isActive ? `${this.label}: ` : this.label
    },
    background() {
      if (this.variant !== 'solo-filled') return 'white'
      return this.isActive ? 'primary-lighten-5' : 'primary-lighten-6'
    },
    isActive() {
      return this.formattedDate != null
    },
  },
  methods: {
    convertDate(date) {
      return !date ? null : moment(date).toDate()
    },
    setDate(date) {
      this.$emit('setDate', date)
    }
  },
}
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: white;
}
</style>