export default [
  {
    value: "DANGEROUS_GOODS",
    title: "Dangerous Goods",
    icon: "mdi-flask-outline",
    warning: "Not Dangerous Goods",
  },
  {
    value: "TEMPERATURE_CONTROLLED",
    title: "Temperature Controlled",
    icon: "mdi-thermometer",
    warning: "Not Temperature Controlled",
  },
  {
    value: "HIGH_VALUE",
    title: "High Value",
    icon: "mdi-cash-multiple",
    warning: "Not High Value",
  },
  {
    value: "FRAGILE",
    title: "Fragile",
    icon: "mdi-glass-wine",
    warning: "Not Fragile",
  },
  {
    value: "NOT_STACKABLE",
    title: "Not Stackable",
    icon: "mdi-stack-overflow",
    warning: "Stackable",
  },
  {
    value: "NOT_TILTABLE",
    title: "Not Tiltable",
    icon: "mdi-arrow-down-bold-box-outline",
    warning: "Tiltable",
  },
  { value: "PHARMA", title: "Pharma", icon: "mdi-pill", warning: "Not Pharma" },
  {
    value: "OUT_OF_GAUGE",
    title: "Out of Gauge",
    icon: "mdi-arrow-expand-all",
    warning: "Not Out of Gauge",
  },
  {
    value: "SPECIAL_LOADING_INSTRUCTIONS",
    title: "Special Loading Instructions",
    icon: "mdi-truck-check",
    warning: "No Special Loading Instructions",
  },
  {
    value: "SPECIAL_UNLOADING_INSTRUCTIONS",
    title: "Special Unloading Instructions",
    icon: "mdi-truck-delivery",
    warning: "No Special Unloading Instructions",
  },
  {
    value: "GOH",
    title: "Garments on Hangers",
    icon: "mdi-hanger",
    warning: "Not GOH (Garments on Hangers)",
  },
];
