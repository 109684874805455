<template>
  <custom-v-text-field
    v-model="value"
    :bg-color="background"
    :class="{ active: isActive }"
    :counter="getType === 'text' ? maxLength > 0 : undefined"
    :label="labelSlot === 'label' ? label : undefined"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :min="min"
    :hide-spin-buttons="true"
    :prefix="labelSlot === 'prefix' ? prefix : undefined"
    :readonly="readonly"
    :rules="rules"
    :step="step"
    :type="getType"
    :variant="variant"
    :max="max"
  />
</template>

<script>
export default {
  name: "DynamicTextField",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxLength: {
      type: Number,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: true,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    preselection: {
      type: [String, Number],
      required: false,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    typeOf: {
      type: String,
      default: () => "String",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelSlot: {
      type: String,
      default: "label",
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      value: undefined,
      isFocused: false,
    };
  },
  computed: {
    prefix() {
      return this.isActive ? `${this.label}: ` : this.label;
    },
    background() {
      if (this.variant !== "solo-filled") return "white";
      return this.isActive ? "primary" : "grey-lighten-5";
    },
    isActive() {
      return this.value != undefined && this.value !== "";
    },
    getType() {
      if (this.typeOf === "Number" || this.typeOf === 'number') return "number";
      if (this.typeOf === "Password") return "password";
      return "text";
    },
  },
  watch: {
    value() {
      this.$emit(
        "setSelectedValues",
          this.searchKey, this.value
      );
    },
    cleared() {
      this.value = undefined;
    },
    preselection: {
      handler() {
        this.value = this.preselection;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: white;
}

.active .v-field--variant-solo-filled :deep(.v-field__input) {
  color: white;
}

.custom-v-text-field :deep(input::-webkit-outer-spin-button),
.custom-v-text-field :deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
}
</style>
