<script>
export default {
  name: "ColFiller"
}
</script>

<template>
  <div/>
</template>

<style scoped>

</style>