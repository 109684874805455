const state = {
  show: false,
  text: "",
  color: "info",
  overlay: false,
  progress: null,
  timeout: 5000
};

const mutations = {
  setSnackbarMessage(state, { text, color }) {
    state.text = text;
    state.color = color;
    state.show = true;
    if (color === 'error') {
      state.timeout = 3600000;
    } else {
      state.timeout = 5000;
    }
  },
  setOverlay(state, overlay) {
    state.overlay = overlay;
  },
  setProgress(state, progress) {
    state.progress = progress;
  },
};

const getters = {
  getOverlay(state) {
    return state.overlay;
  },
  getProgress(state) {
    return state.progress;
  },
};

export const snackbar = {
  namespaced: true,
  state,
  mutations,
  getters,
};
