import { ValidationContext } from "../../models/ValidationContext";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValidationRule } from "../validation/AbstractValidationRule";
import { AbstractValueRule } from "./AbstractValueRule";

enum Type {
    VALUE_IF = 'VALUE_IF'
}

export class ValueConditionRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { condition: AbstractValidationRule, onTrue: any, onFalse: any }) {
        return new ValueConditionRule(obj['condition'], obj['onTrue'], obj['onFalse']);
    }

    type: Type = Type.VALUE_IF;
    condition: AbstractValidationRule;
    onTrue: any;
    onFalse: any;

    constructor(condition: AbstractValidationRule, onTrue: any, onFalse: any) {
        super();
        this.condition = condition;
        this.onTrue = onTrue;
        this.onFalse = onFalse;
    }

    getFieldName(context: ValidationContext): string|undefined {
        if (this.condition.executeAndCatch(context)) {
            return this.onTrue instanceof AbstractValueRule ? (this.onTrue as AbstractValueRule).getFieldName(context) : undefined;
        } else {
            return this.onFalse instanceof AbstractValueRule ? (this.onFalse as AbstractValueRule).getFieldName(context) : undefined;
        }
    }

    getValue(context: ValidationContext): any {
        if (this.condition.executeAndCatch(context)) {
            return this.onTrue instanceof AbstractValueRule ? (this.onTrue as AbstractValueRule).getValue(context) : this.onTrue;
        } else {
            return this.onFalse instanceof AbstractValueRule ? (this.onFalse as AbstractValueRule).getValue(context) : this.onFalse;
        }
    }

    toString(): string {
        const args: any[] = [];
        args.push(this.condition);
        args.push(this.onTrue);
        args.push(this.onFalse);
        return `${this.type}(${args.map(stringifyValue).join(', ')})`;
    }
}

