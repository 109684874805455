import { validationService } from "@/services/validation.service";
import { parseValidationRule } from "./validationEngineParser";
import { AbstractValidationRule } from "./rules/validation/AbstractValidationRule";
import { splitFieldName } from "./helpers/FieldValueHelper";

let customValidation: CustomValidation|undefined = undefined;

class CustomValidation {
    private rule: AbstractValidationRule;
    private fieldRules?: Record<string, AbstractValidationRule|null>;
    private target: object|(() => object);

    type?: string;
    masterCompanyId?: number;

    constructor(json: string, target: object|(() => object), type?: string, masterCompanyId?: number) {
        const { rule, fieldRules } = parseValidationRule(json);
        this.rule = rule;
        this.fieldRules = fieldRules;
        this.target = target;
        this.type = type;
        this.masterCompanyId = masterCompanyId;
    }

    getTarget() {
        if (typeof this.target == 'function') {
            return this.target();
        } else {
            return this.target;
        }
    }

    validate() {
        return this.rule.validateValue(this.getTarget());
    }

    validateField(field: string) {
        // With this behavior: field == null -> no validation, no field -> default validation.
        // Different approach could be: field == null -> use default, no field -> no validation.
        // To change approach just swap "return []" and "return null";
        if (this.fieldRules) {
          if (this.fieldRules[field] instanceof AbstractValidationRule) {
            return this.fieldRules[field]!.validateValue(this.getTarget());
          } else if (this.fieldRules[field] === null) {
            return [];
          } else {
            return null;
          }
        } else {
            const fieldTokens = splitFieldName(field);
            return (this.rule.validateValue(this.getTarget()) ?? []).filter((error) => {
                if (error.field != undefined) {
                    const errorFieldTokens = splitFieldName(field);
                    if (errorFieldTokens.length == fieldTokens.length) {
                        for (const index in fieldTokens) {
                            if (errorFieldTokens[index] != fieldTokens[index]) return false;
                        }
                        return true;   
                    }
                }
                return false;
            });
        }
    }
}

export async function loadCustomValidation(type: string, masterCompanyId: number, target: object|(() => object)): Promise<CustomValidation|undefined> {
    // console.log("LOAD CUSTOM VALIDATION:", type, masterCompanyId, target);
    try {
        const validationJSON = await validationService.getCustomValidation(type, masterCompanyId);
        // console.log("LOADED JSON:", validationJSON);
        if (validationJSON) {
            customValidation = new CustomValidation(validationJSON, target, type, masterCompanyId);
            return customValidation;
        }
    } catch(err: any) {
        console.error("Error loading validation: ", err)
    }
    customValidation = undefined;
    return;
}

export function cleanCustomValidation() {
    // console.log("CLEAN CUSTOM VALIDATION:", customValidation);
    customValidation = undefined;
}

export function getCustomValidation(): CustomValidation|undefined {
    // console.log("GET CUSTOM VALIDATION:", customValidation);
    return customValidation;
}