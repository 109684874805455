import DynamicTextField from '@/components/global/DynamicTextField.vue'
import DynamicTextArea from '@/components/global/DynamicTextArea.vue'
import DynamicSelect from '@/components/global/DynamicSelect.vue'
import DynamicDatePicker from '@/components/global/DynamicDatePicker.vue'
import DynamicSelectMultiple from '@/components/global/DynamicSelectMultiple.vue'
import DynamicSwitch from '@/components/global/DynamicSwitch.vue'
import DynamicCheckbox from '@/components/global/DynamicCheckbox.vue'
import DynamicDateTimePicker from '@/components/global/DynamicDateTimePicker.vue'
import EditableSizeField from "@/components/fields/EditableSizeField.vue";
import EditableWeightField from "@/components/fields/EditableWeightField.vue";

export default {
    components: {
        DynamicTextField,
        DynamicTextArea,
        DynamicSelect,
        DynamicSelectMultiple,
        DynamicSwitch,
        DynamicCheckbox,
        DynamicDatePicker,
        DynamicDateTimePicker,
        EditableWeightField,
        EditableSizeField
    },
}
