<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5001 10.572L11.0001 18L3.50006 10.572C3.00536 10.0906 2.6157 9.51201 2.3556 8.87263C2.09551 8.23325 1.97062 7.54694 1.98879 6.85693C2.00697 6.16691 2.16782 5.48813 2.46121 4.86333C2.75461 4.23853 3.17419 3.68125 3.69354 3.22657C4.21289 2.7719 4.82076 2.42968 5.47887 2.22147C6.13697 2.01327 6.83106 1.94358 7.51743 2.0168C8.20379 2.09001 8.86756 2.30455 9.46693 2.6469C10.0663 2.98925 10.5883 3.45199 11.0001 4.00599C11.4136 3.45602 11.9362 2.99731 12.5352 2.6586C13.1341 2.31988 13.7966 2.10844 14.481 2.03751C15.1654 1.96658 15.8571 2.03769 16.5128 2.24639C17.1685 2.45508 17.7741 2.79687 18.2916 3.25036C18.8091 3.70385 19.2275 4.25928 19.5205 4.88189C19.8135 5.50449 19.9748 6.18088 19.9944 6.8687C20.0139 7.55653 19.8913 8.24099 19.6342 8.87924C19.3771 9.5175 18.991 10.0958 18.5001 10.578"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0001 4L7.70709 7.293C7.51962 7.48053 7.41431 7.73484 7.41431 8C7.41431 8.26516 7.51962 8.51947 7.70709 8.707L8.25009 9.25C8.94009 9.94 10.0601 9.94 10.7501 9.25L11.7501 8.25C12.3468 7.65327 13.1562 7.31803 14.0001 7.31803C14.844 7.31803 15.6534 7.65327 16.2501 8.25L18.5001 10.5M11.5001 13.5L13.5001 15.5M14.0001 11L16.0001 13"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
