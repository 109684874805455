export default [
  { title: "Received", value: "RECEIVED", bg_color: "grey-darken-2" },
  { title: "Processing", value: "PROCESSING", bg_color: "info" },
  { title: "Booked", value: "BOOKED", bg_color: "info" },
  { title: "Partial Booked", value: "PARTIAL_BOOKED", bg_color: "info" },
  {
    title: "Partial In Transit",
    value: "PARTIAL_IN_TRANSIT",
    bg_color: "success",
  },
  { title: "In Transit", value: "IN_TRANSIT", bg_color: "success" },
  {
    title: "Cancellation Requested",
    value: "CANCELLATION_REQUESTED",
    bg_color: "error",
  },
  { title: "Cancelled", value: "CANCELLED", bg_color: "error" },
  { title: "Closed", value: "CLOSED", bg_color: "success" },
];
