<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 13.5864H4C3.73478 13.5864 3.48043 13.6918 3.29289 13.8793C3.10536 14.0669 3 14.3212 3 14.5864V20.5864C3 20.8516 3.10536 21.106 3.29289 21.2935C3.48043 21.4811 3.73478 21.5864 4 21.5864H10C10.2652 21.5864 10.5196 21.4811 10.7071 21.2935C10.8946 21.106 11 20.8516 11 20.5864V14.5864C11 14.3212 10.8946 14.0669 10.7071 13.8793C10.5196 13.6918 10.2652 13.5864 10 13.5864ZM9 19.5864H5V15.5864H9V19.5864ZM20 3.58643H14C13.7348 3.58643 13.4804 3.69178 13.2929 3.87932C13.1054 4.06686 13 4.32121 13 4.58643V10.5864C13 10.8516 13.1054 11.106 13.2929 11.2935C13.4804 11.4811 13.7348 11.5864 14 11.5864H20C20.2652 11.5864 20.5196 11.4811 20.7071 11.2935C20.8946 11.106 21 10.8516 21 10.5864V4.58643C21 4.32121 20.8946 4.06686 20.7071 3.87932C20.5196 3.69178 20.2652 3.58643 20 3.58643ZM19 9.58643H15V5.58643H19V9.58643ZM20 16.5864H18V14.5864C18 14.3212 17.8946 14.0669 17.7071 13.8793C17.5196 13.6918 17.2652 13.5864 17 13.5864C16.7348 13.5864 16.4804 13.6918 16.2929 13.8793C16.1054 14.0669 16 14.3212 16 14.5864V16.5864H14C13.7348 16.5864 13.4804 16.6918 13.2929 16.8793C13.1054 17.0669 13 17.3212 13 17.5864C13 17.8516 13.1054 18.106 13.2929 18.2935C13.4804 18.4811 13.7348 18.5864 14 18.5864H16V20.5864C16 20.8516 16.1054 21.106 16.2929 21.2935C16.4804 21.4811 16.7348 21.5864 17 21.5864C17.2652 21.5864 17.5196 21.4811 17.7071 21.2935C17.8946 21.106 18 20.8516 18 20.5864V18.5864H20C20.2652 18.5864 20.5196 18.4811 20.7071 18.2935C20.8946 18.106 21 17.8516 21 17.5864C21 17.3212 20.8946 17.0669 20.7071 16.8793C20.5196 16.6918 20.2652 16.5864 20 16.5864ZM10 3.58643H4C3.73478 3.58643 3.48043 3.69178 3.29289 3.87932C3.10536 4.06686 3 4.32121 3 4.58643V10.5864C3 10.8516 3.10536 11.106 3.29289 11.2935C3.48043 11.4811 3.73478 11.5864 4 11.5864H10C10.2652 11.5864 10.5196 11.4811 10.7071 11.2935C10.8946 11.106 11 10.8516 11 10.5864V4.58643C11 4.32121 10.8946 4.06686 10.7071 3.87932C10.5196 3.69178 10.2652 3.58643 10 3.58643ZM9 9.58643H5V5.58643H9V9.58643Z"
      fill="currentcolor"
    />
  </svg>
</template>
