<template>
  <CustomFieldWrapper
    class="div-custom-fields"
    :visible="isVisible"
    :vCol="vCol"
  >
    <span class="span-label" v-if="$attrs.label">{{ $attrs.label }}</span>
    <v-select
      v-model="fieldModel"
      v-bind="$attrs"
      :label="null"
      :readonly="isReadonly"
      :rules="fieldRules"
      :style="[$attrs.style, 'text-overflow: ellipsis', 'overflow:hidden', 'white-space: nowrap', 'min-height:40px']"
    >
      <template v-for="slot in parentSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="{ ...scope }" />
      </template>
    </v-select>
  </CustomFieldWrapper>
</template>

<script>
import {computed} from "vue";
import { CustomFieldMixin } from '@/mixins/customField.mixin'
import { CustomFieldWrapperMixin } from '@/components/fields/CustomFieldWrapper.vue'

export default {
  name: "CustomVSelect",
  mixins: [CustomFieldMixin, CustomFieldWrapperMixin],
  inheritAttrs: false,
  setup(props, ctx) {
    const parentSlots = computed(() => Object.keys(ctx.slots));
    return { parentSlots };
  },
};
</script>

<style scoped>
.v-input__control {
  height: 32px;
}

.v-field__input {
  padding: 8px 12px !important;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 15.6px;
}
</style>
