<template>
  <v-row>
    <v-col>
      <DynamicSelect
        is-actor-autocomplete
        :platformCode="platformCode"
        :clearable="false"
        :convert-function="(item) => item.name"
        :extract-value="(item) => item"
        :label="getLabelActor"
        :preselection="object"
        :placeholder="computedPlaceholder"
        :search-function="findAllBusinessPartners"
        :search-key="searchKey"
        @setSelectedValues="setSelectedValues"
      />
    </v-col>
  </v-row>
</template>
<script>
import {businessPartnerService} from "@/services/businessPartner.service";
import DynamicSelect from "@/components/global/DynamicSelect.vue";

export default {
  name: "ActorSelector",
  emits: ["setSelectedValues"],
  components: { DynamicSelect },
  props: {
    searchKey: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    extractValue: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    convertFunction: {
      type: Function,
      required: false,
      default: (item) => item?.name,
    },
    labelCode: {
      type: String,
      required: false,
      default: "Code",
    },
    labelActor: {
      type: String,
      required: false,
      default: "Actor",
    },
    hideCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraParameters: {
      type: Object,
      required: false,
      default: () => null,
    },
    preselection: {
      type: [Number, Object],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      object: this.defaultValue ?? null,
      platformCode: null,
      businessPartnersList: [],
      oldObject: null,
      actorPreselection: {},
    };
  },
  methods: {
    findAllBusinessPartners(searchText, offset, limit) {
      limit = limit > 20 ? 20 : limit;
      let filter = {
        globalSearch: searchText,
      };
      return businessPartnerService.browsePartner(offset, limit, filter, []);
    },
    setSelectedValues(key, value) {
      this.object = value;
      this.$emit("setSelectedValues", key, this.extractValue(value));
    },
  },
  watch: {
    object(newVal) {
      if (newVal) {
        this.platformCode = newVal.platformCode;
      } else {
        this.platformCode = null;
      }
    },
    preselection: {
      handler(newVal) {
        if (this.oldObject === newVal) {
          return;
        }
        this.oldObject = newVal;

        if (!newVal) {
          this.object = null;
          return;
        }

        if (newVal && typeof newVal === "object") {
          this.object = newVal;
          return;
        }

        if (newVal && typeof newVal === "number") {
          businessPartnerService
            .getById(newVal)
            .then((response) => {
              this.object = {
                businessPartnerId: response.id,
                name: response.partner?.name,
                address: response.partner?.address,
                city: response.partner?.city,
                zip: response.partner?.zipCode,
                country: response.partner?.country,
                state: response.partner?.state,
                locode: response.partner?.locode,
                code:
                  response.customReference ?? response.partner?.platformCode,
                codeIdentifier: response.customReference
                  ? "CUSTOMER_REFERENCE"
                  : "PLATFORM_CODE",
                platformCode: response.partner?.platformCode,
                contacts: [],
              };
            })
            .catch((error) => {
              console.error(error);
              this.setSnackbarMessage({
                text: "Error while loading actor",
                color: "error",
              });
            });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedPlaceholder() {
      return (
        this.extraParameters.placeholder ??
        "Select a " + this.getLabelActor.toLowerCase()
      );
    },
    getLabelActor() {
      if (
        this.extraParameters != null &&
        this.extraParameters.labelActor != null
      ) {
        return this.extraParameters.labelActor;
      }
      return this.labelActor;
    },
  },
};
</script>
