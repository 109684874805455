// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/styles/main.css";

// Vuetify
import {createVuetify} from "vuetify";
import * as labsComponents from "vuetify/labs/components";
import {VBtn} from "vuetify/lib/components/index.mjs";
import customSvgs from "@/components/svgs/customSvgs";

const myCustomTheme = {
  dark: false,
  colors: {
    background: "#f2f2f9",
    "primary-light": "#e6e7f5",
    "primary-light-hover": "#DADBEF",
    "primary-light-active": "#B3B4DF",
    primary: "#070971",
    "primary-hover": "#080B88",
    "primary-active": "#070A79",
    "primary-dark": "#070971",
    "primary-dark-hover": "#05075B",
    "primary-dark-active": "#040544",
    "primary-darker": "#030435",
    "warning-light": "#FCF2E7",
    "warning-light-hover": "#FBEBDA",
    "warning-light-active": "#F7D5B3",
    warning: "#E5780B",
    "warning-hover": "#CE6C0A",
    "warning-active": "#B76009",
    "warning-dark": "#AC5A08",
    "warning-dark-hover": "#894807",
    "warning-dark-active": "#673605",
    "warning-darker": "#502A04",
    "success-light": "#E9F7E6",
    "success-light-hover": "#DEF3D9",
    "success-light-active": "#BBE7B1",
    success: "#25B003",
    "success-hover": "#219E03",
    "success-active": "#1E8D02",
    "success-dark": "#1C8402",
    "success-dark-hover": "#166A02",
    "success-dark-active": "#114F01",
    "success-darker": "#0D3E01",
    "error-light": "#FDE9E6",
    "error-light-hover": "#FCDDD9",
    "error-light-active": "#F9BAB0",
    error: "#EC1F00",
    "error-hover": "#D41C00",
    "error-active": "#BD1900",
    "error-dark": "#B11700",
    "error-dark-hover": "#8E1300",
    "error-dark-active": "#6A0E00",
    "error-darker": "#530B00",
    "secondary-light": "#FCF2E6",
    "secondary-light-hover": "#FBEBD9",
    "secondary-light-active": "#F7D6B0",
    secondary: "#E57C00",
    "secondary-hover": "#CE7000",
    "secondary-active": "#B76300",
    "secondary-dark": "#AC5D00",
    "secondary-dark-hover": "#894A00",
    "secondary-dark-active": "#673800",
    "secondary-darker": "#502B00",
  },
};

export default createVuetify({
  aliases: {
    VBtnPrimary: VBtn,
    VBtnSecondary: VBtn,
  },
  defaults: {
    global: {
      style: "font-size: 0.875rem !important;",
    },

    // Containment
    VBtn: {class: "text-none generic-button", elevation: 0},
    VBtnPrimary: {
      color: "primary-darker text-none",
      class: "primary-button generic-button",
      variant: "flat"
    },
    VBtnSecondary: {
      color: "primary-darker",
      class: "secondary-button generic-button text-none",
      variant: "outlined"
    },
    VCard: {
      density: "compact",
      elevation: 0,
    },
    VList: {},
    VChip: {},
    VDivider: {
      color: "primary-light",
      class: "my-4",
      style: "opacity: 1;",
    },
    VExpansionPanels: {},
    VExpansionPanel: {
      elevation: 0,
    },
    VMenu: {},
    VDialog: {},
    VBottomSheet: {},
    VOverlay: {},
    VToolbar: {},
    VTooltip: {},
    VSheet: {},

    // Navigation
    VAppBar: {},
    VNavigationDrawer: {},
    VPagination: {
      activeColor: "primary-dark-active",
      density: "compact",
    },
    VBottomNavigation: {},
    VBreadcrumbs: {},
    VFooter: {},
    VSystemBar: {},
    VTabs: {},

    VAutocomplete: {
      density: "compact",
      hideDetails: "auto",
      variant: "outlined",
    },
    VTextField: {
      density: "compact",
      baseColor: "primary-light-hover",
      color: "primary-light-hover",
      hideDetails: "auto",
      variant: "outlined",
      style: "--v-field-border-opacity: 1;",
    },
    VSwitch: {
      color: "primary",
      density: "compact",
      hideDetails: "auto",
      variant: "outlined",
    },
    VCheckbox: {
      baseColor: "primary-light-active",
      color: "primary",
      hideDetails: "auto",
    },
    VCheckboxBtn: {
      baseColor: "primary-light-active",
      color: "primary",
      hideDetails: "auto",
    },
    VRadioGroup: { color: "primary-dark-hover" },
    VRadio: { baseColor: "primary-light-active" },
    VFileInput: { density: "compact", variant: "outlined" },
    VSelect: {
      baseColor: "primary-light-hover",
      color: "primary-light-hover",
      density: "compact",
      hideDetails: "auto",
      variant: "outlined",
    },
    VTextarea: {
      baseColor: "primary-light-hover",
      color: "primary-light-hover",
      variant: "outlined",
      hideDetails: "auto",
    },

    VRow: {
      dense: "true",
    },

    VStepper: {
      border: "",
      elevation: 0,
    },

    VProgressCircular: {
      color: "primary",
    },
    VProgressLinear: {
      color: "primary",
    },

    VAvatar: {
      color: "primary-dark-active",
    },
  },
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme,
    },
  },
  components: {
    ...labsComponents,
  },
  options: {
    customProperties: true,
  },
  icons: {
    sets: {
      custom: customSvgs,
    },
  },
});
