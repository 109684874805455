<template>
  <div class="d-flex flex-row">
    <v-tooltip
        v-for="icon in specialHandlingIcons"
        :key="icon.value"
        :text="icon.title"

        location="bottom"
    >
      <template v-slot:activator="{  props }">
        <v-icon
            v-bind="props"
        >
          {{ icon.icon }}
        </v-icon>
      </template>
      <span>{{ icon.title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import specialHandlingCode from "@/enums/specialHandlingEnum";

export default {
  name: 'SpecialHandlingIcons',
  props: {
    item: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    specialHandlingIcons() {
      let value = this.item[this.column.key];
      let icons = [];
      // reduce to only code from the value
      if (!value) {
        return icons;
      }
      value = value.map((item) => item.code);
      //search inside specialHandlingCode for the icon

      for (let i = 0; i < value.length; i++) {
        let code = value[i];
        let icon = specialHandlingCode.find((item) => item.value === code);
        if (icon) {
          icons.push(icon);
        } else {
          console.debug('no icon found for code: ' + code)
        }
      }
      return icons;
    }
  }
}
</script>