const state = {
  expandAll: 0,
};

const mutations = {
  setExpandAll(state, expandAll) {
    state.expandAll = expandAll;
  },
};

export const panels = {
  namespaced: true,
  state,
  mutations,
};
