<template>
  <v-checkbox
    v-model="value"
    :false-value="false"
    :readonly="readonly"
    :rules="rules"
    :true-value="true"
    class="mx-1"
    density="compact"
    inset
  >
    <template v-slot:label>
      <span class="label text-caption"> {{ label }}</span>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "DynamicCheckbox",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: true,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    preselection: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    extractValue: {
      type: Function,
      default: (item) => item,
    },
  },
  data() {
    return {
      value: undefined,
      isFocused: false,
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
    cleared() {
      this.value = undefined;
    },
    preselection() {
      this.loadPreselectedItem();
    },
  },
  methods: {
    loadPreselectedItem() {
      if (this.preselection == undefined || this.preselection == null)
        this.value = false;
      else this.value = this.preselection;
    },
    setValue(value) {
      this.$emit("setSelectedValues", this.searchKey, this.extractValue(value));
    },
  },
  mounted() {
    this.loadPreselectedItem();
  },
};
</script>

<style scoped>
.v-checkbox :deep(label) {
  pointer-events: none;
}

.label {
  white-space: nowrap;
}
</style>
