import { AbstractValidationRule } from '../rules/validation/AbstractValidationRule';
import { defaultPresets } from '../presets/defaultPresets';

export class ValidationContext {
    fieldValue: any;
    fieldName?: string;
    root: any;
    presets: Record<string, AbstractValidationRule>;

    constructor(fieldValue: any, fieldName: string|undefined = "", root: any = fieldValue, presets: Record<string, AbstractValidationRule> = {}) {
        this.fieldValue = fieldValue;
        this.fieldName = fieldName;
        this.root = root;
        this.presets = {
            ...defaultPresets,
            ...presets
        };
    }

    static fromValue(value: any): ValidationContext {
        return new ValidationContext(value, "", value, {});
    }

    getFieldName(childFieldName?: string): string|undefined {
        if (!childFieldName) return undefined;
        if (!childFieldName) return this.fieldName;
        let result = this.fieldName;
        if (this.fieldName && !childFieldName.startsWith("[")) result += ".";
        result += childFieldName;
        return result;
    }

    createCopy(): ValidationContext {
        return new ValidationContext(this.fieldValue, this.fieldName, this.root, this.presets);
    }

    createCopyWithValueAndFieldName(fieldValue: any, fieldName?: string): ValidationContext {
        return new ValidationContext(fieldValue, fieldName, this.root, this.presets);
    }

    createChild(fieldValue: any, childFieldName: string): ValidationContext {
        return new ValidationContext(fieldValue, this.getFieldName(childFieldName), this.root, this.presets);
    }
}

