import { ValidationContext } from "@/validationEngine/models/ValidationContext";
import { AbstractValueRule } from "./AbstractValueRule";

enum Type {
    CURRENT_VALUE = "CURRENT_VALUE"
}

export class CurrentValueRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { field: string, defaultValue: any }) {
        return new CurrentValueRule(obj['field'], obj['defaultValue']);
    }

    type: Type = Type.CURRENT_VALUE;
    fieldName: string;
    defaultValue: any;
    
    constructor(fieldName: string, defaultValue?: any) {
        super();
        this.fieldName = fieldName;
        this.defaultValue = defaultValue;
    }

	getFieldName(context: ValidationContext) {
		return context.fieldName;
	}

	getValue(context: ValidationContext) {
		return context.fieldValue;
	}

    toString() {
        return `${this.type}()`;
    }
}
