import {h} from "vue";
import PenSvg from "@/components/svgs/PenIcon.vue";
import TrashSvg from "@/components/svgs/TrashAltSvg.vue";
import AppSvg from "@/components/svgs/AppSvg.vue";
import BookOpenSvg from "@/components/svgs/BookOpenSvg.vue";
import CalendarSvg from "@/components/svgs/CalendarSvg.vue";
import CodeBranchSvg from "@/components/svgs/CodeBranchSvg.vue";
import DownloadAltSvg from "@/components/svgs/DownloadAltSvg.vue";
import FilterSvg from "@/components/svgs/FilterSvg.vue";
import InfoCircleSvg from "@/components/svgs/InfoCircleSvg.vue";
import PlaneSvg from "@/components/svgs/PlaneSvg.vue";
import PlusSvg from "@/components/svgs/PlusSvg.vue";
import RefreshSvg from "@/components/svgs/RefreshSvg.vue";
import ShipSvg from "@/components/svgs/ShipSvg.vue";
import SubwaySvg from "@/components/svgs/SubwaySvg.vue";
import TableSvg from "@/components/svgs/TableSvg.vue";
import TruckSvg from "@/components/svgs/TruckSvg.vue";
import AccountSvg from "@/components/svgs/AccountSvg.vue";
import MonitorEyeSvg from "@/components/svgs/MonitorEyeSvg.vue";
import ShoppingBagSvg from "@/components/svgs/ShoppingBagSvg.vue";
import CreateDashboardSvg from "@/components/svgs/CreateDashboardSvg.vue";
import HandshakeSvg from "@/components/svgs/HandshakeSvg.vue";
import ChartLineSvg from "@/components/svgs/ChartLineSvg.vue";
import HistorySvg from "@/components/svgs/HistorySvg.vue";
import EnvelopeSvg from "@/components/svgs/EnvelopeSvg.vue";
import SettingsSvg from "@/components/svgs/SettingsSvg.vue";
import ArrowsResizeVSvg from "@/components/svgs/ArrowsResizeVSvg.vue";
import ArrowsShrinkVSvg from "@/components/svgs/ArrowsShrinkVSvg.vue";
import AngleRightSvg from "@/components/svgs/AngleRightSvg.vue";
import DangerSvg from "@/components/svgs/DangerSvg.vue";
import ListSvg from "@/components/svgs/ListSvg.vue";
import DocumentSvg from "@/components/svgs/DocumentSvg.vue";
import KeySvg from "@/components/svgs/KeySvg.vue";
import BackpackSvg from "@/components/svgs/BackpackSvg.vue";
import PackageSvg from "@/components/svgs/PackageSvg.vue";
import MapMarkerSvg from "@/components/svgs/MapMarkerSvg.vue";
import EyeSvg from "@/components/svgs/EyeSvg.vue";
import EyeSlashSvg from "@/components/svgs/EyeSlashSvg.vue";
import CalendarAltSvg from "@/components/svgs/CalendarAltSvg.vue";
import SaveSvg from "@/components/svgs/SaveSvg.vue";
import CopySvg from "@/components/svgs/CopySvg.vue";
import ClipboardSvg from "@/components/svgs/ClipboardSvg.vue";
import CloseSvg from "@/components/svgs/CloseSvg.vue";
import MailFastSvg from "@/components/svgs/MailFastSvg.vue";
import LayersAltSvg from "@/components/svgs/LayersAltSvg.vue";

const customSvgNameToComponent = {
  app: AppSvg,
  backpack: BackpackSvg,
  "book-open": BookOpenSvg,
  calendar: CalendarSvg,
  "code-branch": CodeBranchSvg,
  copy: CopySvg,
  clipboard: ClipboardSvg,
  close: CloseSvg,
  danger: DangerSvg,
  document: DocumentSvg,
  "download-alt": DownloadAltSvg,
  filter: FilterSvg,
  "info-circle": InfoCircleSvg,
  list: ListSvg,
  key: KeySvg,
  pen: PenSvg,
  plane: PlaneSvg,
  packages: PackageSvg,
  plus: PlusSvg,
  refresh: RefreshSvg,
  save: SaveSvg,
  ship: ShipSvg,
  subway: SubwaySvg,
  table: TableSvg,
  "trash-alt": TrashSvg,
  truck: TruckSvg,
  account: AccountSvg,
  "create-dashboard": CreateDashboardSvg,
  "monitor-eye": MonitorEyeSvg,
  "shopping-bag": ShoppingBagSvg,
  handshake: HandshakeSvg,
  "chart-line": ChartLineSvg,
  history: HistorySvg,
  message: EnvelopeSvg,
  settings: SettingsSvg,
  expand: ArrowsResizeVSvg,
  collapse: ArrowsShrinkVSvg,
  "chevron-right": AngleRightSvg,
  "map-marker-outline": MapMarkerSvg,
  "mail-fast": MailFastSvg,
  eye: EyeSvg,
  "eye-slash": EyeSlashSvg,
  "calendar-alt": CalendarAltSvg,
  "layerAlt": LayersAltSvg
};

const customSvgs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export default customSvgs;
