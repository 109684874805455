import {createRouter, createWebHistory} from "vue-router";
import {permissionsData as permissionData, permissionsData} from "@/configs/permissions/index.js";
import {nextTick} from 'vue';

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes: permissionsData.getRouterItems(),
});

router.beforeEach((to, from, next) => {
    //const publicPages = ["landing", "login", "usernotfound"];
    //const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem("authToken");
    let checkAuth = permissionData.checkPermission(to);
    /*console.debug("from", from)
    console.debug("to", to)*/
    if (!loggedIn && !checkAuth) {
        localStorage.setItem("redirectAfterLogin", to.fullPath)
        localStorage.setItem("tenantChoosen", to?.params?.tenantId)
        return next({name: "login"});
    }

    if (checkAuth) {
        const checkIsEditing = permissionsData.checkIsEditing(next)
        if (!checkIsEditing)
            return next();
    } else {
        return next({
            name: '403'
        })
    }
});

router.afterEach((to) => {
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    })
})

router.back = function () {
    //Without printing the history, the back function is not working.
    console.debug(window.history);
    window.history.back();
};

export default router;
