import store from "@/store";

export function authHeader(withTenant = true, withCompany = true) {
    let token = store.getters["account/getToken"];

    let tenantId = store.getters["account/getTenant"]?.id ?? null;

    let companyId = store.getters["account/getCompany"]?.id ?? null;


    if (token) {
        let headers = {
            Authorization: "Bearer " + token,
        }

        if (withTenant) {
            headers["x-tenant-id"] = tenantId ?? 0
        }

        if (withCompany) {
            headers["x-company-id"] = companyId ?? 0
        }
        return headers;
    } else {
        return {};
    }
}
