<template>
  <div class="d-flex flex-column ga-2 mb-4">
    <span style="font-weight: 600; font-size: 13px; line-height: 15.6px"
      >Transport mode</span
    >
    <div class="d-flex ga-8">
      <v-avatar
        v-for="(item, index) in loadedItems"
        :key="index"
        size="48"
        class="transport-mode-decorator"
        :style="getBackgroundColor(item)"
        @click="toggleValue(item)"
      >
        <svg-icon-component :icon="item.SVGicon" :color="getIconColor(item)">
        </svg-icon-component>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import SvgIconComponent from "./SvgIconComponent.vue";
import {reactive} from "vue";

export default {
  data() {
    return {
      searchText: "",
      selectedValues: [],
      loadedItems: [],
      totalItems: 0,
      offset: 0,
      limit: 15,
    };
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
    },
    preselection: {
      type: [Object, String],
    },
    searchFunction: {
      type: Function,
      required: true,
    },
    convertFunction: {
      type: Function,
      required: false,
      default: (item) => item,
    },
    extractValue: {
      type: Function,
      required: false,
      default: (item) => item.value,
    },
    extractValues: {
      type: Function,
      required: false,
      default: (item, that) => {
        if (!item || item.length === 0) return undefined;
        return item
          .map((i) => that.extractValue(i))
          .reduce((acc, curr) => acc + "|" + curr);
      },
    },
    setSelectedValues: {
      type: Function,
      required: false,
      default: async (loadedItems, selectedItems) => selectedItems,
    },
  },
  computed: {
    getBackgroundColor() {

      return (value) => {
        return reactive({
          "background-color": this.selectedValues?.some(
              (item) => item.value === value.value
          )
            ? "rgb(var(--v-theme-primary-dark)) !important"
            : "white !important",
        });
      };
    },
    getIconColor() {
      return (value) =>
          !this.selectedValues?.some((item) => item.value === value.value)
          ? "primary-dark"
          : "white !important";
    },
  },
  methods: {
    executeRemoteSearch() {
      return this.searchFunction(this.searchText, this.offset, this.limit)
        .then((response) => {
          if (this.offset === 0) {
            if (response.resultList) this.loadedItems = response.resultList;
            else this.loadedItems = response;
          } else {
            if (response.resultList)
              this.loadedItems.push(...response.resultList);
            else this.loadedItems.push(...response);
          }
          this.totalItems = response.totalCount
            ? response.totalCount
            : response.length;
        })
        .catch((e) => {
          console.error(e);
          this.setSnackbarMessage({
            message: "Error while searching for " + this.searchKey,
            color: "error",
          });
        });
    },
    setSelection() {
      let valuesSelected = "";
      if (Array.isArray(this.selectedValues) && this.selectedValues.length === 0) {
        this.$emit("setSelectedValues", this.searchKey, undefined);
        return;
      }

      // if (this.selectedValues?.length) {
      if (this.extractValues != null) {

        console.log(this.extractValues(this.selectedValues, this))

        valuesSelected = this.extractValues(this.selectedValues, this);
      } else {
        console.log("selectedValues", this.selectedValues)
        this.selectedValues.forEach((item, index) => {
          valuesSelected += this.extractValue(item);
          if (index !== this.selectedValues.length - 1) valuesSelected += "\\|";
        });
        if (!valuesSelected.length) valuesSelected = undefined;
      }
      // }

      this.$emit("setSelectedValues", this.searchKey, valuesSelected);
    },
    loadPreselectedItem() {
      // if (!this.preselection) return
      this.setSelectedValues(this.loadedItems, this.preselection)
        .then((r) => {
          if (r) {
            this.selectedValues = r;
          }
          else this.selectedValues = [];
          return this.selectedValues;
        })
        .then((selected) => {
          if (!selected) return;
          selected.forEach((select) => {
            if (select?.value) {
              if (!this.loadedItems.some((item) => item.value === select.value))
                this.loadedItems.push(select);
            } else {
              if (!this.loadedItems.some((item) => item === select))
                this.loadedItems.push(select);
            }
          });
        });
    },
    toggleValue(item) {

      if (item.value) {
        const indexToRemove = this.selectedValues.findIndex((i) => i.value === item.value);
        if (indexToRemove > -1) {
          this.selectedValues.splice(indexToRemove, 1);
        } else {
          this.selectedValues.push(item);
        }
      } else {
        const indexToRemove = this.selectedValues.indexOf(item);
        if (indexToRemove > -1) {
          this.selectedValues.splice(indexToRemove, 1);
        } else {
          this.selectedValues.push(item);
        }
      }
    },
  },
  watch: {
    selectedValues: {
      handler() {
        this.setSelection();
      },
      deep: true,
    },
    preselection: {
      handler() {
        this.loadPreselectedItem();
      },
      deep: true,
    },
  },
  emits: ["setSelectedValues"],
  mounted() {
    this.executeRemoteSearch().then(() => this.loadPreselectedItem());
  },
  components: {
    SvgIconComponent,
  },
};
</script>

<style scoped>
.transport-mode-decorator {
  border: thin solid rgb(var(--v-theme-primary-light));
}
</style>
