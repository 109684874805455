import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "../value/AbstractValueRule";
import { AbstractValidationRule } from "./AbstractValidationRule";

enum Type {
    ALL = "ALL",
    ANY = "ANY",
    NONE = "NONE"
}

export class GroupValidationRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { type: Type, rule: AbstractValidationRule, values: any[] }) {
        return new GroupValidationRule(obj['type'], obj['rule'], obj['values']);
    }

    type: Type;
    rule: AbstractValidationRule;
    values: AbstractValueRule|any[];

    constructor(type: Type, rule: AbstractValidationRule, values: any[]) {
        super();
        this.type = type;
        this.rule = rule;
        this.values = values;
    }

    private getCollection(context: ValidationContext): any[] {
        if (this.values instanceof AbstractValueRule) {
            return this.values.getValue(context);
        } else {
            return this.values;
        }
    }

	execute(context: ValidationContext): boolean {
        let i = 0;
        switch (this.type) {
            case Type.ALL:
                for (const value of this.getCollection(context)) {
                    if (!this.rule.executeAndCatch(context.createChild(value, "[" + (i++) + "]"))) return false;
                }
                return true;
            case Type.ANY:
                for (const value of this.getCollection(context)) {
                    if (this.rule.executeAndCatch(context.createChild(value, "[" + (i++) + "]"))) return true;
                }
                return false;
            case Type.NONE:
                for (const value of this.getCollection(context)) {
                    if (this.rule.executeAndCatch(context.createChild(value, "[" + (i++) + "]"))) return false;
                }
                return true;
            default:
                throw new Error("Unknown rule type " + this.type);
        }
	}

    validate(context: ValidationContext): ValidationError[] {
        const errors: ValidationError[] = [];

        let i = 0;
        try {
            switch (this.type) {
                case Type.ALL:
                    for (const value of this.getCollection(context)) {
                        errors.push(...this.rule.validate(context.createChild(value, "[" + (i++) + "]")));
                    }
                    break;
                case Type.ANY:
                    for (const value of this.getCollection(context)) {
                        const ruleErrors: ValidationError[] = this.rule.validate(context.createChild(value, "[" + (i++) + "]"));
                        if (ruleErrors.length) {
                            errors.push(...ruleErrors);
                        } else {
                            return [];
                        }
                    }
                    break;
                case Type.NONE:
                    for (const value of this.getCollection(context)) {
                        const ruleErrors: ValidationError[] = this.rule.validate(context.createChild(value, "[" + (i++) + "]"));
                        if (!ruleErrors.length) {
                            return [this.createValidationError(value, context, `Found valid value for ${this.rule} while expecting NONE.`)];
                        }
                    }
                    break;
                default:
                    errors.push(new ValidationError("Unknown rule type \"" + this.type + "\""));
            }
        } catch(e: any) {
            errors.push(new ValidationError(e.getMessage()));
        }

        return errors;
    }

    toString() {
        return `${this.type}(${this.rule}, ${stringifyValue(this.values)})"`;
    }
}
