<template>
  <v-icon
    :color="disabled ? '#D3D3D3' : color"
    :icon="
      icon &&
      (icon.startsWith('md-') ||
        icon.startsWith('mdi-') ||
        icon.startsWith('custom:'))
        ? icon
        : 'custom:' + icon
    "
    @click="disabled ? null : $attrs.click"
    :style="{ cursor: cursor }"
  ></v-icon>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cursor: {
      type: String,
      default: "pointer",
    },
  },
};
</script>
