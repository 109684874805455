<template>
  <v-textarea
      v-model="value"
      :bg-color="background"
      :counter="maxlength"
      :label="labelSlot == 'label' ? label : undefined"
      :maxlength="maxlength"
      :prefix="labelSlot == 'prefix' ? prefix : undefined"
      :readonly="readonly"

      :rules="rules"
      :variant="variant"
      class="xs-input"
      color="primary-darken"
      density="compact"
      flat
      rows="3"
      @blur="onBlur"
      @focus="onFocus"
  />
</template>

<script>
export default {
  name: "DynamicTextArea",
  emits: ["setSelectedValues"],
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxlength: {
      type: Number,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: true,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    preselection: {
      type: [String, Number],
      required: false,
    },
    variant: {
      type: String,
      default: "outlined", //'solo-filled'
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelSlot: {
      type: String,
      default: "label",
    },
  },
  data() {
    return {
      value: undefined,
      isFocused: false,
    };
  },
  computed: {
    getStyles() {
      if (this.variant == "underlined") return;
      return {
        "background-color": !(this.value.length > 0)
            ? "white"
            : this.isFocused
                ? "white"
                : "#283593",
        color: !(this.value.length > 0)
            ? undefined
            : this.isFocused
                ? undefined
                : "white",
        "border-radius": "4px",
        "min-width": "150px",
      };
    },
    prefix() {
      return this.isActive ? `${this.label}: ` : this.label;
    },
    background() {
      if (this.variant !== "solo-filled") return "white";
      if (this.isActive) return this.isFocused ? "white" : "#283593";
      else return "white";
    },
    isActive() {
      return this.value != undefined && this.value !== "";
    },
  },
  watch: {
    value() {
      if (this.value == null || this.value == undefined || this.value === "")
        this.value = null;
      this.$emit("setSelectedValues", this.searchKey, this.value);
    },
    cleared() {
      this.value = undefined;
    },
    preselection() {
      this.loadPreselectedItem();
    },
  },
  methods: {
    loadPreselectedItem() {
      if (this.preselection == undefined || this.preselection == null) return;
      this.value = this.preselection;
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
  mounted() {
    this.loadPreselectedItem();
  },
};
</script>

<style scoped>
/*.xs-input :deep(input) {
  padding-top: 0.4rem !important;
  padding-bottom: 0.2rem !important;
  padding-inline: 0.5rem !important;
}
.xs-input :deep(.v-field) {
  --v-field-input-padding-bottom: 0 !important;
}*/
</style>
