import rules from "@/configs/rules";
import { CustomFieldVisibilityKey, isReadOnly, isVisible } from "@/helpers/fieldVisibility";
import { inject } from "vue";

export const CustomFieldMixin = {
    props: {
        'v-model': {
            type: Object
        },
        fieldVisibilitySettings: {
            type: Object,
            default: () => inject(CustomFieldVisibilityKey)
        },
        fieldItem: {
            type: Object
        },
        parentField: {
            type: [String, Boolean],
            transform: (value) => (value === true ? '' : value),
            default: ''
        },
        field: {
          type: String
        },
        visibilityField: {
            type: String
        },
        validationField: {
            type: String
        },
        visible: {
          type: Boolean,
          default: true
        },
        readonly: {
          type: Boolean,
          default: false
        },
        rules: {
          type: Array,
          default: () => []
        }
    },
    computed: {
        fullVisibilityField() {
            const visibilityField = this.visibilityField ?? this.field;
            if (this.parentField && visibilityField) {
                return `${this.parentField}.${visibilityField}`;
            } else if (this.parentField === '') {
                return visibilityField;
            }
        },
        fullValidationField() {
            const validationField = this.validationField ?? this.field;
            if (this.parentField && validationField) {
                return `${this.parentField}.${validationField}`;
            } else if (this.parentField === '') {
                return validationField;
            }
        },
        commonRules() {
            return rules
        },
        fieldModel: {
            set(val) {
                // console.log(`SET FIELD ("${this.field}") VALUE:`, val);
                if (this.fieldItem && this.field) {
                    this.fieldItem[this.field] = val;
                }
            },
            get() {
                // console.log(`GETTING FIELD ("${this.field}") VALUE:`, this.fieldItem?.[this.field]);
                if (this.fieldItem && this.field) {
                    return this.fieldItem[this.field];
                }
            }
        },
        isVisible() {
            if (!this.visible) return false;
            if (this.fullVisibilityField) {
                return isVisible(this.fullVisibilityField, this.fieldVisibilitySettings?.settings);
            }
            return true;
        },
        isReadonly() {
            if (this.readonly) return true;
            if (this.fullVisibilityField) {
                return isReadOnly(this.fullVisibilityField, this.fieldVisibilitySettings?.settings);
            }
            return false;
        },
        fieldRules() {
            if (this.fullValidationField) {
                return [rules.customValidation(this.fullValidationField, ...this.rules)]
            } else {
                return this.rules;
            }
        }
    },
    methods: {
        subField(field) {
            if (this.field && field) {
                return `${this.field}.${field}`
            }
        }
    }
}
