import {createApp} from "vue";
//import {loadFonts} from "./plugins/webfontloader";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import VCalendar from "v-calendar";
import ComponentSelector from "@/components/global/ComponentSelector.vue";
import CustomVTextField from "@/components/fields/CustomVTextField.vue";
import ConfirmExitDialog from "@/components/global/ConfirmExitDialog.vue";
import ActorAutocomplete from "@/components/autocompletes/ActorAutocomplete.vue";
import TransportModeFiltering from "@/components/TransportModeFiltering.vue"
import PurchaseOrderStatusChip from "@/components/chips/PurchaseOrderStatusChip.vue"
import SpecialHandlingIcons from "@/components/SpecialHandlingIcons.vue"
import ColFiller from "@/components/ColFiller.vue"
import {RecycleScroller} from "vue3-virtual-scroller";

import "v-calendar/style.css";

import GlobalMixin from "./mixins/global.mixin";
import CustomVAutocomplete from "@/components/fields/CustomVAutocomplete.vue";
import CustomVSelect from "@/components/fields/CustomVSelect.vue";
import CustomVCheckbox from "@/components/fields/CustomVCheckbox.vue";
import "@fontsource/nunito-sans"; // Defaults to weight 400
import "@fontsource/nunito-sans/400.css"; // Specify weight
import "@fontsource/nunito-sans/400-italic.css";
import CustomVTextArea from "@/components/fields/CustomVTextArea.vue"; // Specify weight and style
import SvgIconComponent from "@/components/SvgIconComponent.vue";

//loadFonts();

const app = createApp(App);

app.config.globalProperties.$msalInstance = {};

app
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VCalendar, { componentPrefix: "vc" })
  .component("ComponentSelector", ComponentSelector)
  .component("CustomVTextField", CustomVTextField)
  .component("CustomVAutocomplete", CustomVAutocomplete)
  .component("CustomVSelect", CustomVSelect)
  .component("CustomVTextArea", CustomVTextArea)
  .component("CustomVCheckbox", CustomVCheckbox)
  .component("ConfirmExitDialog", ConfirmExitDialog)
  .component("RecycleScroller", RecycleScroller)
  .component("SvgIconComponent", SvgIconComponent)
    .component("ActorAutocomplete", ActorAutocomplete)
    .component("TransportModeFiltering", TransportModeFiltering)
    .component("ColFiller", ColFiller)
    .component("PurchaseOrderStatusChip", PurchaseOrderStatusChip)
    .component("SpecialHandlingIcons", SpecialHandlingIcons)
  .mixin(GlobalMixin)
  .mount("#app");
