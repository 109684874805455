import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { AbstractValidationRule } from "./AbstractValidationRule";

enum Type {
    AND = "AND",
    OR = "OR"
}
    
export class AggregationRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { type: Type, rules: AbstractValidationRule[] }) {
        return new AggregationRule(obj['type'], obj['rules']);
    }

    type: Type;
    rules: AbstractValidationRule[] = [];

    constructor(type: Type, rules: AbstractValidationRule[]) {
        super();
        this.type = type;
        this.rules = rules;
    }

	execute(context: ValidationContext): boolean {
        switch (this.type) {
            case Type.AND:
                for (const rule of this.rules) {
                    if (rule && !rule.executeAndCatch(context)) {
                        return false;
                    }
                }
                return true;
            case Type.OR:
                for (const rule of this.rules) {
                    if (rule && rule.executeAndCatch(context)) {
                        return true;
                    }
                }
                return false;
            default:
                // error?
                return false;
        }
	}

    validate(context: ValidationContext): ValidationError[] {
        const errors: ValidationError[] = [];
        switch (this.type) {
            case Type.AND:
                for (const rule of this.rules) {
                    errors.push(...rule.validate(context));
                }
                break;
            case Type.OR:
                for (const rule of this.rules) {
                    const ruleErrors = rule.validate(context);
                    if (!ruleErrors?.length) {
                        return [];
                    } else {
                        errors.push(...ruleErrors);
                    }
                }
                break;
            default:
                errors.push(new ValidationError("Unknown rule type \"" + this.type + "\""));
        }
        return errors;
    }

    public toString() {
        return `${this.type}(${this.rules.join(", ")})`;
    }
}
