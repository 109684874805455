import { ValidationContext } from "../../models/ValidationContext";
import { AbstractValueRule } from "./AbstractValueRule";

enum Type {
    NOW = "NOW",
    NULL = "NULL"
}

export class ConstantRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { type: Type }) {
        return new ConstantRule(obj['type']);
    }

    type: Type;

    constructor(type: Type) {
        super();
        this.type = type;
    }

    public getFieldName(context: ValidationContext): string|undefined {
       return undefined;
    }

    public getValue(context: ValidationContext): any {
        switch(this.type) {
            case Type.NOW:
                return new Date();
            default:
                return null;
        }
    }

    public toString() {
        return `${this.type}()`;
    }
}
