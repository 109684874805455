<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 7.58643H16V6.58643C16 5.52556 15.5786 4.50814 14.8284 3.758C14.0783 3.00785 13.0609 2.58643 12 2.58643C10.9391 2.58643 9.92172 3.00785 9.17157 3.758C8.42143 4.50814 8 5.52556 8 6.58643V7.58643H5C4.73478 7.58643 4.48043 7.69178 4.29289 7.87932C4.10536 8.06686 4 8.32121 4 8.58643V19.5864C4 20.3821 4.31607 21.1451 4.87868 21.7077C5.44129 22.2704 6.20435 22.5864 7 22.5864H17C17.7956 22.5864 18.5587 22.2704 19.1213 21.7077C19.6839 21.1451 20 20.3821 20 19.5864V8.58643C20 8.32121 19.8946 8.06686 19.7071 7.87932C19.5196 7.69178 19.2652 7.58643 19 7.58643ZM10 6.58643C10 6.05599 10.2107 5.54728 10.5858 5.17221C10.9609 4.79714 11.4696 4.58643 12 4.58643C12.5304 4.58643 13.0391 4.79714 13.4142 5.17221C13.7893 5.54728 14 6.05599 14 6.58643V7.58643H10V6.58643ZM18 19.5864C18 19.8516 17.8946 20.106 17.7071 20.2935C17.5196 20.4811 17.2652 20.5864 17 20.5864H7C6.73478 20.5864 6.48043 20.4811 6.29289 20.2935C6.10536 20.106 6 19.8516 6 19.5864V9.58643H8V10.5864C8 10.8516 8.10536 11.106 8.29289 11.2935C8.48043 11.4811 8.73478 11.5864 9 11.5864C9.26522 11.5864 9.51957 11.4811 9.70711 11.2935C9.89464 11.106 10 10.8516 10 10.5864V9.58643H14V10.5864C14 10.8516 14.1054 11.106 14.2929 11.2935C14.4804 11.4811 14.7348 11.5864 15 11.5864C15.2652 11.5864 15.5196 11.4811 15.7071 11.2935C15.8946 11.106 16 10.8516 16 10.5864V9.58643H18V19.5864Z"
      fill="currentcolor"
    />
  </svg>
</template>
