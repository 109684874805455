import {getCustomValidation} from "@/validationEngine/validationStore.ts";
import ContainerValidator from "container-validator";

function getRule(regex, message) {
  return (v) => {
    if (!v) return;
    return new RegExp(regex).test(v) || message;
  };
}

function translateDate(value) {
  //if value is string transform it to date
  if (typeof value === "string") {
    value = new Date(value);
  }
  return value;
}

const rules = {
  customValidation(field, ...defaultRules) {
    return (v) => {
      const validator = getCustomValidation();
      if (validator) {
        const errors = validator.validateField(field);
        if (errors) {
          if (errors.length) return errors.map((e) => e.message).join(', ');
          return true;
        }
      }
      for (const rule of defaultRules) {
        if (rule && typeof rule == 'function') {
          const res = rule(v);
          if (res !== true) return res;
        }
      }
      return true;
    }
  },
  required: (v) => {
    if (v === null || v === undefined || v === '') return "Required";
    return true;
  },
  requiredIf: (condition) => {
    if (condition) {
      return rules.required
    }
    return true;
  },
  requiredNumber: (v) => !!v || (!v && v === 0) || "Required",
  requiredAsNumber: (v) =>
      (v != null &&
          !(isNaN(parseInt(v)) && isNaN(parseFloat(v))) && v !== "") || "Required",
  notEmptyString: (v) =>
    v == null || v === "" || v.trim().length > 0 || "Required",
  onlyAlphaNumeric: (v) =>
    v == null ||
    v === "" ||
      new RegExp("^[0-9\\sA-Za-z]*$").test(v) ||
    "Only valid A-Z, 0-9, or spaces",
  requiredBoolean: (v) => v !== undefined || v != null || "Required",
  integerNumber() {
    return (v) =>
      v == null || v === "" || Number.isInteger(v) || `Not a natural number`;
  },
  validContainerNumber: (containerNumber) => {
    if (!containerNumber) {
      return true;
    }

    let validator = new ContainerValidator();
    if (validator.isValid(containerNumber)) {
      return true;
    }
    return validator.getErrorMessages().join(', ');
  },
  minNumber(min) {
    return (v) =>
      v == null ||
      v === "" ||
      v >= min ||
      `Not less than ${min}`;
  },
  greaterThanZero: (v) => v > 0 || "Must be greater than zero",
  maxNumber(max) {
    return (v) =>
      v == null ||
      v === "" ||
      v <= max ||
      Number.isNaN(v) ||
      `Not more than ${max}`;
  },
  minLength(min) {
    return (v) => v == null || v.length >= min || `Min length ${min}`;
  },
  maxLength(max) {
    return (v) => v == null || v.length <= max || `Max length ${max}`;
  },
  notEmpty(v) {
    return v == null || v === "" || v.trim().length > 0 || `Not empty`;
  },
  regExp(regex, message) {
    return (v) => v == null || v === "" || new RegExp(regex).test(v) || message;
  },
  notAfterDate(date) {

    return (v) =>
        date === null ||
        date === undefined ||
      v == null ||
      v === "" ||
        translateDate(v) <= translateDate(date) ||
        `Not after ${new Date(date).toISOString().split("T")[0]}`;
  },
  notBeforeDate(date) {
    return (v) =>
        date === null ||
        date === undefined ||
      v == null ||
      v === "" ||
        translateDate(v) >= translateDate(date) ||
        `Not before ${new Date(date).toISOString().split("T")[0]}`;
  },
  notBeforeToday() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return (v) => v == null || v === "" || translateDate(v) >= today || "Date must be in the future";

  },
  validNumber: (v) => {
     let result = new RegExp(/^-?[0-9]+(\.?[0-9]+)?$/).test(v);
      return v == null || v === "" ||  result || `Not a number`
  },
  maxFileSize(fileInMb) {
    return (v) => {
      if (!v) return;
      if (!v[0]) return
      return v[0].size < fileInMb * 1024 * 1024 || `File size must be less than ${fileInMb} MB!`;
    }
  },

  validEnum(enums) {
    //from an array of object, title / value, check if the value is present
    return (v) =>
      v == null ||
      v === "" ||
      enums.map((e) => e.value).includes(v) ||
      `Not valid`;
  },
  emailFormat: getRule(
    "^([-!#-'*+/-9=?A-Z^-~]+(\\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \\t]|(\\\\[\\t -~]))+\")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])$",
    `Respect format email@example.com`
  ),
  ruleLat: (v) => (!v || new RegExp("^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$").test(v))
      || "Latitude must be between -90 and 90 and a precision of 6 digits after the dot",
  ruleLng: (v) => (!v || new RegExp("^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$").test(v))
      || "Longitude must be between -180 and 180 and a precision of 6 digits after the dot",
  //allows negative coordinates
  ruleGpsMaxPrecision: (v) => (!v || new RegExp("^(-)?[0-9]{1,3}(\\.[0-9]{1,6})?$").test(v) || "Max 6 digits as precision after the dot"),
};

export default rules;
