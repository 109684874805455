import {authHeader} from "@/helpers/auth-header";
import {commons} from "./commons";
import store from "@/store";

export const businessPartnerService = {
    browse,
    getById,
    save,
    deleteRequest,
    sendBatchToBackend,
    browsePartner,
    browseCompanies,
    browseCarriers,
};

const resourceUrl = process.env.VUE_APP_BACKEND_URL + `/company/`;

const endpoint = "partner";


function getTenantId() {
    return store.getters['account/getTenant']?.id
}


function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/` + id,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function save(obj) {
    const requestOptions = {
        method: obj.id != null ? "PUT" : "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(obj),
    };
    let url = resourceUrl + getTenantId() + "/" + endpoint;
    if (obj.id != null) {
        url = url + "/" + obj.id;
    }
    return fetch(url, requestOptions).then(commons.handleJsonResponse);
}

function deleteRequest(id) {
    const requestOptions = {
        method: "DELETE",
        headers: {...authHeader(), "Content-Type": "application/json"},
    };
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + "/" + id,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function browse(offset, limit, filter, sortingList) {
    if (!getTenantId()) {
        return {
            resultList: [],
            totalCount: 0,
        };
    }

    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList,
        }),
    };
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/browse`,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function sendBatchToBackend(batch) {
    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(batch),
    };

    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/upload`,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function browsePartner(offset, limit, filter, sortingList) {
    if (!getTenantId()) {
        return {
            resultList: [],
            totalCount: 0,
        };
    }

    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList,
        }),
    };
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/browsePartners`,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function browseCompanies(offset, limit, filter, sortingList) {
    if (!getTenantId()) {
        return {
            resultList: [],
            totalCount: 0,
        };
    }

    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList,
        }),
    };
    
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/browseCompanies`,
        requestOptions
    ).then(commons.handleJsonResponse);
}

function browseCarriers(offset, limit, filter, sortingList) {
    if (!getTenantId()) {
        return {
            resultList: [],
            totalCount: 0,
        };
    }

    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify({
            offset: offset,
            limit: limit,
            filter: filter,
            sortingList: sortingList,
        }),
    };
    return fetch(
        resourceUrl + getTenantId() + "/" + endpoint + `/browseCarriers`,
        requestOptions
    ).then(commons.handleJsonResponse);
}