<template>
  <custom-v-text-field
    :bg-color="background"
    :label="rowEdit ? '' : column.title"
    :modelValue="item[column.key]"
    :readonly="readonly"
    :rules="column.rules"
    hide-spin-buttons
    min="0"
    :max="$attrs.max"
    step="0.00001"
    type="number"
    v-bind="$attrs"
    variant="outlined"
    validate-on="blur"
  >
    <template v-slot:append-inner>
      <span>{{ weightUnit }}</span>
    </template>
  </custom-v-text-field>
</template>
<script>
import weightUnitEnum from "@/enums/weightUnitEnum";

export default {
  name: "EditableWeightField",
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rowEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    weightUnit() {
      if (!this.item?.weightUnit) {
        return null;
      }
      return weightUnitEnum.find((item) => item.value === this.item.weightUnit)
        ?.abbreviation;
    },
    background() {
      return "white";
    },
  },
};
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: white;
}

.active .v-field--variant-solo-filled :deep(.v-field__input) {
  color: white;
}
</style>
