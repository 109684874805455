<template>
  <v-dialog v-model="show" width="fit-content">
    <v-card>
      <v-card-title class="bg-primary">
        <span class="text-white"> {{ title }} </span>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-10">
        <v-container class="pa-0" fluid>
          <v-row dense>
            <v-col class="d-flex justify-center">
              <span> {{ text }} </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-end px-3">
        <v-btn-secondary size="small" @click="cancelFun(); show = false">
          <span>Cancel</span>
        </v-btn-secondary>
        <v-btn-primary size="small" @click="submitFun()">
          <span>Confirm</span>
        </v-btn-primary>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmExitDialog',
  data() {
    return {
      show: false,
      title: undefined,
      text: undefined,
      submitFun: undefined,
      cancelFun: undefined
    }
  },
  created: function () {
    this.$store.watch(
        (state) => state.edit.dialog.text,
        () => {
          this.show = this.$store.state.edit.dialog.show
          setTimeout(() => {
            this.title = this.$store.state.edit.dialog.title
            this.text = this.$store.state.edit.dialog.text
            this.submitFun = this.$store.state.edit.dialog.submit
            this.cancelFun = this.$store.state.edit.dialog.cancel
          }, this.show ? 0 : 300);
        },
    )
  },
}
</script>
