import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "../value/AbstractValueRule";
import { AbstractValidationRule } from "./AbstractValidationRule"

enum Type {
    IS_NULL = "IS_NULL",
    IS_NOT_NULL = "IS_NOT_NULL",
    IS_EMPTY = "IS_EMPTY",
    IS_NOT_EMPTY = "IS_NOT_EMPTY"
}

const labels: Record<Type, String> = {
    [Type.IS_NULL]: "Null",
    [Type.IS_NOT_NULL]: "Not Null",
    [Type.IS_EMPTY]: "Empty",
    [Type.IS_NOT_EMPTY]: "Not Empty"
}

export class AssertionRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { type: Type, value: any }) {
        return new AssertionRule(obj['type'], obj['value']);
    }

    static evaluate(assertionType: Type, value: any): boolean {
        switch (assertionType) {
            case Type.IS_NULL:
                return value === null || value === undefined;
            case Type.IS_NOT_NULL:
                return value !== null && value !== undefined;
            case Type.IS_EMPTY:
                if (Array.isArray(value) || typeof value == "string") {
                    return !value?.length;
                } else if (value === null || value === undefined) {
                    return true;
                } else {
                    throw new Error(assertionType + " can only be performed on a String or Collection value");
                }
            case Type.IS_NOT_EMPTY:
                if (value === null || value === undefined) {
                    return false;
                } else if (Array.isArray(value) || typeof value == "string") {
                    return !!value?.length;
                } else {
                    throw new Error(assertionType + " can only be performed on a String or Collection value");
                }
            default:
                throw new Error("Unknown assertion type " + assertionType);
        }
    }

    type: Type;
    value: any;

    constructor(type: Type, value: any) {
        super();
        this.type = type;
        this.value = value;
    }

    public execute(context: ValidationContext): boolean {
        return AssertionRule.evaluate(
            this.type,
            this.getCurrentValue(this.value, context)
        );
    }

    public validate(context: ValidationContext): ValidationError[] {
        const errors: ValidationError[] = [];

        try {
            if (!this.execute(context)) {
                errors.push(this.createValidationError(this.value, context).setMessage(this.createMessage()));
            }
        } catch(e: any) {
            // console.error(e);
            errors.push(this.createValidationError(this.value, context).setMessage(e.message));
        }

        return errors;
    }
    
    private createMessage() {
        if (this.type == Type.IS_NOT_EMPTY || this.type == Type.IS_NOT_NULL) {
            return 'Required';
        } else {
            return "Does not satisfy: " + labels[this.type];
        }
    }


    public toString() {
        return `${this.type}(${stringifyValue(this.value)})`;
    }

}
