<template>
  <template v-if="visible">
    <v-col
      v-if="vCol"
      v-bind="{ ...$attrs, ...vCol }"
    >
      <slot></slot>
    </v-col>
    <div
      v-else
      v-bind="$attrs"
    >
      <slot></slot>
    </div>
  </template>
</template>

<script>
const CustomFieldWrapper = {
  name: "CustomFieldWrapper",
  props: {
    visible: {
      type: [Boolean, String],
      default: true
    },
    vCol: {
      type: Object
    }
  },
  methods: {
    /** Prevent some values from being inputted in the input field
     *
     * @param event
     */
    preventValues(event) {
      if (this.$attrs.type !== "number") {
        this.$emit("keydown", event);
        return;
      }
      if (event.key === "e" || event.key === "E" || event.key === "+") {
        event.preventDefault();
        return;
      }
      if (event.key === "-") {
        if (this.$attrs.min != null) {
          if (this.$attrs.min >= 0) {
            event.preventDefault();
            return;
          }
        }
        if (
            (this.$attrs.modelValue != null && (('' + this.$attrs.modelValue).includes("-") || ('' + this.$attrs.modelValue).length > 0))
        ) {
          event.preventDefault();
          return;
        }
      }
      this.$emit("keydown", event);
    },
    cleanUpStep(value) {
      if (!this.$attrs.step) {
        return value;
      }
      const step = "" + this.$attrs.step;
      if (!step.includes(".")) {
        if (("" + value).length < 6) {
          return value;
        }
        value = parseInt(("" + value).slice(0, 6));
      } else if (step.includes(".") && ("" + value).includes(".")) {
        //step can be 0.001, i need to parse by the decimal number present
        let stepDecimals = step.split(".")[1].length;
        let valueParts = ("" + value).split(".");
        let valueDecimals = valueParts[1].length;
        if (valueDecimals > stepDecimals) {
          //always floor the value
          //value to str then get the right part
          value = valueParts[0] + "." + valueParts[1].slice(0, stepDecimals);
        }
      }
      return value;
    },
    cleanUpNumber(value) {
      if (this.$attrs.type !== "number" || Number.isNaN(value)) {
        return value;
      }

      value = this.cleanUpStep(value);

      if (!(value === null || value === undefined || value === "")) {
        value = this.cleanUpMin(value);

        value = this.cleanUpMax(value);
      }

      return value;
    },
    updateModelValue(value) {
      value = this.cleanUpNumber(value);
      if (this.$attrs.type === "number" && Number.isNaN(value)) {
        return;
      }
      this.$emit("update:modelValue", value);
    },
    cleanUpMin(value) {
      if (this.$attrs.min == null) {
        return value;
      }
      if (value < this.$attrs.min) {
        value = this.$attrs.min;
      }
      return value;
    },
    cleanUpMax(value) {
      if (this.$attrs.max == null) {
        return value;
      }
      if (value > this.$attrs.max) {
        value = this.$attrs.max;
      }
      return value;
    },
  }
};

export const CustomFieldWrapperMixin = {
  components: {
    CustomFieldWrapper
  },
  props: {
    col: {
      type: Object
    },
    'col-cols': {
      type: [String, Number]
    },
    'col-sm': {
      type: [String, Number]
    },
    'col-md': {
      type: [String, Number]
    },
    'col-lg': {
      type: [String, Number]
    },
    'col-xl': {
      type: [String, Number]
    },
    'col-xxl': {
      type: [String, Number]
    }
  },
  computed: {
    useVCol() {
      return this.col || this.colCols || this.colSm || this.colMd || this.colLg || this.colXl || this.colXxl;
    },
    vCol() {
      if (this.useVCol) {
        return {
          ...(this.col),
          ...(this.colCols ? { cols: this.colCols } : {}),
          ...(this.colSm ? { sm: this.colSm } : {}),
          ...(this.colMd ? { md: this.colMd } : {}),
          ...(this.colLg ? { lg: this.colLg } : {}),
          ...(this.colXl ? { xl: this.colXl } : {}),
          ...(this.colXxl ? { xxl: this.colXxl } : {})
        }
      }
      return;
    }
  }
}

export default CustomFieldWrapper;
</script>

<style scoped>
.v-input :deep(.v-field__input:disabled) {
  background-color: #E6E7F5;
}
</style>