export const reports = {
  namespaced: true,
  state: () => ({
    tableauToken: null,
  }),
  getters: {
    tableauToken: (state) => state.tableauToken,
  },
  mutations: {
    setTableauToken(state, token) {
      state.tableauToken = token;
    },
  },
};
