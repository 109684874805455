import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "../value/AbstractValueRule";
import { AbstractValidationRule } from "./AbstractValidationRule";

enum Type {
    EQUALS = "EQUALS",
    NOT_EQUALS = "NOT_EQUALS",
    CONTAINS = "CONTAINS",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
    LESS_THAN_EQUALS = "LESS_THAN_EQUALS"
}

const labels: Record<Type, string> = {
    [Type.EQUALS]: "Equals to",
    [Type.NOT_EQUALS]: "Not Equals to",
    [Type.CONTAINS]: "Contains",
    [Type.STARTS_WITH]: "Starts with",
    [Type.ENDS_WITH]: "Snds with",
    [Type.GREATER_THAN]: "Greater than",
    [Type.LESS_THAN]: "Less than",
    [Type.GREATER_THAN_EQUALS]: "Greather than or Equals to",
    [Type.LESS_THAN_EQUALS]: "Less than or Equals to"
}

function parseValue(value: any): any {
    if (typeof value == 'string') {
        if (!isNaN(Number(value))) return Number(value);
        // use only date template?
        const toDate = new Date(value);
        if (!isNaN(toDate.getTime())) return toDate;
        return value.length;
    }
    if (Array.isArray(value)) return value.length;
    return value;
}
    
export class ComparisonRule extends AbstractValidationRule {
    static Type = Type;

    static create(obj: { type: Type, value: any, compareValue: any }) {
        return new ComparisonRule(obj['type'], obj['value'], obj['compareValue']);
    }
    
    static evaluate(type: Type, value: any, compareValue: any): boolean {
        switch (type) {
            case Type.EQUALS:
                return value == compareValue;
            case Type.NOT_EQUALS:
                return value != compareValue;
            case Type.CONTAINS:
                if (Array.isArray(value) || typeof value == "string") {
                    return value.includes(compareValue);
                } else {
                    throw new Error(type + " can only be performed on Strings or Collections");
                }
            case Type.STARTS_WITH:
                if (typeof value == "string") {
                    return value.startsWith(`${compareValue}`);
                } else {
                    throw new Error(type + " can only be performed on Strings");
                }
            case Type.ENDS_WITH:
                if (typeof value == "string") {
                    return value.endsWith(`${compareValue}`);
                } else {
                    throw new Error(type + " can only be performed on Strings");
                }
            case Type.GREATER_THAN:
                // check date
                return parseValue(value) > parseValue(compareValue);
            case Type.LESS_THAN:
                // check date
                return parseValue(value) < parseValue(compareValue);
            case Type.GREATER_THAN_EQUALS:
                // check date
                return parseValue(value) >= parseValue(compareValue);
            case Type.LESS_THAN_EQUALS:
                // check date
                return parseValue(value) <= parseValue(compareValue);
            default:
                throw new Error("Unknown comparison type " + type);
        }
    }

    // @NonNull
    type: Type;
    value: AbstractValueRule|any;
    compareValue: AbstractValueRule|any;

    constructor(type: Type, value: any, compareValue: any) {
        super();
        this.type = type;
        this.value = value;
        this.compareValue = compareValue;
    }

    public execute(context: ValidationContext): boolean {
        return ComparisonRule.evaluate(
            this.type,
            this.getCurrentValue(this.value, context),
            (this.compareValue instanceof AbstractValueRule) ? this.compareValue.getValue(context) : this.compareValue
        );
    }

    public validate(context: ValidationContext): ValidationError[] {
        const errors: ValidationError[] = [];

        let _compareValue: string;
        if ((this.compareValue instanceof AbstractValueRule)) {
            const fieldName = this.compareValue.getFieldName(context);
            if (fieldName) {
                _compareValue = fieldName
            } else {
                _compareValue = stringifyValue(this.compareValue.getValue(context));
            }
        } else {
            _compareValue = stringifyValue(this.compareValue);
        }

        try {
            if (!this.execute(context)) {
                errors.push(this.createValidationError(this.value, context).setMessage("Does not satisfy: " + labels[this.type] + " " + _compareValue));
            }
        } catch(e: any) {
            // console.error(e);
            errors.push(this.createValidationError(this.value, context).setMessage(e.message));
        }

        return errors;
    }

    public toString() {
        const args: any[] = [];
        args.push(this.value);
        args.push(this.compareValue);
        return `${this.type}(${args.map(stringifyValue).join(", ")})`;
    }
}
