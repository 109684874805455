import _ from "lodash";

export const tableHeadersConfig = {
  namespaced: true,

  state: {
    purchaseOrdersTableHeadersConfig: null,
    shipmentRequestsTableHeadersConfig: null,
    shipmentOrdersTableHeadersConfig: null,
    shipmentsTableHeadersConfig: null,
    trackingTableHeadersConfig: null,
  },
  getters: {
    getPurchaseOrdersTableHeadersConfig(state) {
      return state.purchaseOrdersTableHeadersConfig;
    }, // -> getters['tableHeadersConfig/getPurchaseOrdersTableHeadersConfig']
    getShipmentRequestsTableHeadersConfig(state) {
      return state.shipmentRequestsTableHeadersConfig;
    }, // -> getters['tableHeadersConfig/getShipmentRequestsTableHeadersConfig']
    getShipmentOrdersTableHeadersConfig(state) {
      return state.shipmentOrdersTableHeadersConfig;
    }, // -> getters['tableHeadersConfig/getShipmentOrdersTableHeadersConfig']
    getShipmentsTableHeadersConfig(state) {
      return state.shipmentsTableHeadersConfig;
    }, // -> getters['tableHeadersConfig/getShipmentsTableHeadersConfig']
    getTrackingTableHeadersConfig(state) {
      return state.trackingTableHeadersConfig;
    }, // -> getters['tableHeadersConfig/getTrackingTableHeadersConfig']
  },
  mutations: {
    storePurchaseOrdersTableHeadersConfig(state, headers) {
      state.purchaseOrdersTableHeadersConfig = _.cloneDeep(headers);
    },
    storeShipmentRequestsTableHeadersConfig(state, headers) {
      state.shipmentRequestsTableHeadersConfig = _.cloneDeep(headers);
    },
    storeShipmentOrdersTableHeadersConfig(state, headers) {
      state.shipmentOrdersTableHeadersConfig = _.cloneDeep(headers);
    },
    storeShipmentsTableHeadersConfig(state, headers) {
      state.shipmentsTableHeadersConfig = _.cloneDeep(headers);
    },
    storeTrackingTableHeadersConfig(state, headers) {
      state.trackingTableHeadersConfig = _.cloneDeep(headers);
    },
  },
};
