<template>
  <CustomFieldWrapper
    class="div-custom-fields"
    :visible="isVisible"
    :vCol="vCol"
  >
    <span 
      v-if="$attrs.label"
      :style="[$attrs.style, 'text-overflow: ellipsis']"
      class="span-label"
    >{{$attrs.label}}</span>
    <v-autocomplete
      v-model="fieldModel"
      v-bind="$attrs"
      :label="null"
      :readonly="isReadonly"
      :rules="fieldRules"
    >
      <template v-for="slot in parentSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="{...scope}" />
      </template>
    </v-autocomplete>
  </CustomFieldWrapper>
</template>

<script>
import {computed} from "vue";
import { CustomFieldMixin } from '@/mixins/customField.mixin'
import { CustomFieldWrapperMixin } from '@/components/fields/CustomFieldWrapper.vue'

export default {
  name: "CustomVAutocomplete",
  mixins: [CustomFieldMixin, CustomFieldWrapperMixin],
  inheritAttrs: false,
  setup(props, ctx) {
    const parentSlots = computed(() => Object.keys(ctx.slots))
    return { parentSlots };
  },
};
</script>

<style scoped>
  .div-custom-fields :deep(.v-input--horizontal .v-input__append) {
    margin-inline-start: 8px;
  }

  .div-custom-fields :deep(.v-input--horizontal .v-input__append:empty) {
    margin-inline-start: 0;
  }
</style>