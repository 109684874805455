import { ValidationContext } from "../../models/ValidationContext";
import { ValidationError } from "../../models/ValidationError";
import { AbstractValueRule } from "../value/AbstractValueRule";

export abstract class AbstractValidationRule {
    public abstract execute(context: ValidationContext): boolean;
    public abstract validate(context: ValidationContext): ValidationError[];
    
    public executeAndCatch(context: ValidationContext): boolean {
        try {
            return this.execute(context);
        } catch (e) {
            return false;
        }
    }
    protected createValidationError(value: any, context: ValidationContext, message?: string): ValidationError {
        if (value instanceof AbstractValueRule) {
            const fieldName = (value as AbstractValueRule).getFieldName(context);
            if (fieldName) {
                return ValidationError.fromField(fieldName, message);
            } else {
                return ValidationError.fromValue(value, message);
            }
        } else if (value == null && context != null) {
            return ValidationError.fromField(context.fieldName ?? "");
        } else {
            return ValidationError.fromValue(value, message);
        }
    }

    public validateValue(value: any): ValidationError[] {
        return this.validate(new ValidationContext(value));
    }
    
    protected getCurrentFieldName(value: any, context: ValidationContext ): string|undefined {
        if (value instanceof AbstractValueRule) {
            return (value as AbstractValueRule).getFieldName(context);
        } else if (this.isNullOrUndefined(value) && !this.isNullOrUndefined(context)) {
            return context.fieldName;
        } else {
            return "";
        }
    }

    protected getCurrentValue(value: any, context: ValidationContext ): any {
        if (value instanceof AbstractValueRule) {
            return (value as AbstractValueRule).getValue(context);
        } else if (this.isNullOrUndefined(value) && !this.isNullOrUndefined(context)) {
            return context.fieldValue;
        } else {
            return value;
        }
    }

    protected isNullOrUndefined(value: any) {
        return value === null || value === undefined;
    }
}

