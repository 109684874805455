<template>
  <DatePicker
      :date="value"
      :label="label"
      :labelSlot="labelSlot"

      :readonly="readonly"
      :rules="rules"
      :variant="variant"
      @setDate="value = $event"
  />
</template>

<script>
import DatePicker from '@/components/global/DatePicker.vue'

export default {
  name: 'DynamicDatePicker',
  emits: ['setSelectedValues'],
  components: {DatePicker},
  props: {
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    searchKey: {
      type: String,
      required: true,
    },
    cleared: {
      type: Number,
      default: 0,
    },
    preselection: {
      type: [Date, String, Object],
      required: false,
    },
    variant: {
      type: String,
      default: 'outlined',
    },
    extractValue: {
      type: Function,
      default: (item) => item,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelSlot: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      value: undefined,
    }
  },
  watch: {
    cleared() {
      this.value = undefined
    },
    value(value) {
      this.$emit('setSelectedValues', this.searchKey, this.extractValue(value))
    },
    preselection() {
      this.loadPreselectedItem()
    },
  },
  methods: {
    loadPreselectedItem() {
      this.value = this.preselection
    },
  },
  mounted() {
    this.loadPreselectedItem()
  },
}
</script>
