import store from "@/store/index";
import { inject } from "vue";
import { provide } from "vue";

export const CustomFieldVisibilityKey = 'CustomFieldVisibility';

function findField(field, settings) {
    if (!settings) return;
    if (settings[field]) return settings[field];
    // add search with wildcards? e.g. details.*.sku
}

export function isVisible(field, settings) {
    const visible = findField(field, settings) !== "HIDDEN";
    // console.debug(`Visibility for field "${field}": ${visible}`, !settings ? '(No settings provided)' : '');
    return visible;
}

export function isReadOnly(field, settings) {
    const readonly = findField(field, settings) === "READONLY";
    // console.debug(`Readonly for field "${field}": ${readonly}`, !settings ? '(No settings provided)' : '');
    return readonly;
}

export class CustomFieldVisibility {
    settings;

    constructor(objectVisibility) {
        let visibilityFields = store.getters["account/getTenant"]?.visibilityFields;
        console.debug('Tenant Visibility Fields:', visibilityFields); 

        if (!visibilityFields) {
            console.debug("No visibility fields");
            return;
        }
        try {
            const localJson = JSON.parse(visibilityFields);
            this.settings = localJson[objectVisibility];
            console.debug("Object visibility fields:", objectVisibility, this.settings);
        } catch (e) {
            console.error("Error parsing visibility fields", e)
        }
    }

    isVisible(field) {
        return isVisible(field, this.settings);
    }

    isReadOnly(field) {
        return isReadOnly(field, this.settings);
    }
}

export function createCustomFieldVisibilityProvider(component, objectVisibility, override = false) {
    if (override || !inject.call(component, CustomFieldVisibilityKey)) {
        provide.call(component, CustomFieldVisibilityKey, new CustomFieldVisibility(objectVisibility));
    }
}

export default CustomFieldVisibility;