<template>
  <v-chip
    class="cursor-help"
    variant="flat"
    :color="chipBackground"
    :style="{ border: `1px solid ${chipBorderColor}` }"
  >
    {{ status(item.status) }}
    <v-tooltip
      max-width=300
      location="end"
      activator="parent"
      open-delay=500
    >{{ tooltipText }}</v-tooltip>
  </v-chip>
</template>

<script>
import purchaseOrderStatusEnum from "@/enums/purchaseOrderStatusEnum";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {};
  },
  computed: {
    chipBackground() {
      switch (this.item.status) {
        case "BOOKED":
        case "TOTALY_BOOKED": // Used for details
          return "#2F75B5";
        case "RECEIVED":
          return "#777777";
        case "CANCELLED":
          return "#D41C00";
        case "PROCESSING":
          return "#090C97";
        case "PARTIAL_BOOKED":
          return "#BADAF6";
        case "IN_TRANSIT":
          return "#3A7A0F";
        case "PARTIAL_IN_TRANSIT":
          return "#C2EDA4";
        case "CANCELLATION_REQUESTED":
          return "#FFC2C2";
        case "CLOSED":
          return "#1C8402";
        default:
          return "#CBD0DD";
      }
    },
    tooltipText() {
      switch (this.item.status) {
        case "BOOKED":
          return "The order has been totally forwarded to the relevant Service Provider."
        case "TOTALY_BOOKED":
          return "The order has been totally forwarded to the relevant Service Provider.";
        case "RECEIVED":
          return "The order has been correctly received by the platform and can be booked.";
        case "CANCELLED":
          return "The order has been cancelled.";
        case "PROCESSING":
          return "The order has been converted into a Shipment Request. Control Tower is reviewing.";
        case "PARTIAL_BOOKED":
          return "The order has been partially forwarded to the relevant Service Provider.";
        case "IN_TRANSIT":
          return "The order has been totally shipped.";
        case "PARTIAL_IN_TRANSIT":
          return "The order has been partially shipped.";
        case "CANCELLATION_REQUESTED":
          return "An order cancellation has been requested.";
        case "CLOSED":
          return "The order has been completely delivered to destination.";
        default:
          return "";
      }
    },
    chipBorderColor() {
      switch (this.item.status) {
        case "BOOKED":
        case "TOTALY_BOOKED": // Used for details
          return "#3976AE";
        case "RECEIVED":
          return "#E6E6E6";
        case "CANCELLED":
          return "#D41C00";
        case "PROCESSING":
          return "#090C97";
        case "PARTIAL_BOOKED":
          return "#B3D4F2";
        case "IN_TRANSIT":
          return "#3A7A0F";
        case "PARTIAL_IN_TRANSIT":
          return "#A9D08E";
        case "CANCELLATION_REQUESTED":
          return "#FF9999";
        case "CLOSED":
          return "#1C8402";
        default:
          return "#CBD0DD";
      }
    },
    status() {
      return (status) => {
        const statusObject = purchaseOrderStatusEnum.find(
          (item) => item.value === status
        );

        return statusObject?.title || (status || "").split('_').map((s) => (s.slice(0, 1) + s.slice(1).toLowerCase())).join(' ') || 'Unknown';
      };
    },
  },
};
</script>
