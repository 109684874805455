import { AbstractValidationRule } from "./rules/validation/AbstractValidationRule";
import { AggregationRule } from "./rules/validation/AggregationRule";
import { AssertionRule } from "./rules/validation/AssertionRule";
import { ComparisonRule } from "./rules/validation/ComparisonRule";
import { ConditionRule } from "./rules/validation/ConditionRule";
import { ConstantRule } from "./rules/value/ConstantRule";
import { CurrentValueRule } from "./rules/value/CurrentValueRule";
import { FieldRule } from "./rules/value/FieldRule";
import { GroupValidationRule } from "./rules/validation/GroupValidationRule";
import { PresetValidationRule } from "./rules/validation/PresetValidationRule";
import { ValueAggregationRule } from "./rules/value/ValueAggregationRule";
import { ValueConditionRule } from "./rules/value/ValueConditionRule";
import { ValueTransformationRule } from "./rules/value/ValueTransformationRule";
import { FieldValidationRule } from "./rules/validation/FieldValidationRule";

type ValidationRule = {
    rule: AbstractValidationRule,
    fieldRules?: Record<string, AbstractValidationRule|null>
};

export function parseValidationRule(rule: string): ValidationRule {
    const parsed = JSON.parse(rule, (key: string, value: any) => {
        if (value?.type) {
            for (const clz of [
                AggregationRule,
                AssertionRule,
                ComparisonRule,
                ConditionRule,
                ConstantRule,
                CurrentValueRule,
                FieldRule,
                FieldValidationRule,
                GroupValidationRule,
                PresetValidationRule,
                ValueAggregationRule,
                ValueConditionRule,
                ValueTransformationRule
            ]) {
                if ((clz.Type as any)[value.type.toUpperCase()]) {
                    value.type = value.type.toUpperCase();
                    return clz.create(value);
                }
            }
        }
        return value;
    });
    if (!parsed || typeof parsed != 'object') throw Error("Invalid rule string: not a validation rule.");

    if (Array.isArray(parsed)) {
        return {
            rule: new AggregationRule(AggregationRule.Type.AND, parsed)
        };
    } else if (!(parsed instanceof AbstractValidationRule)) {
        if (Object.values(parsed).every((value) => (value == null || value instanceof AbstractValidationRule))) {
            const fieldRules = Object.entries(parsed)
                .filter(([key, value]) => (!!value))
                .reduce((rules, [key, value]) => {
                    rules[key] = new FieldValidationRule(key, value as AbstractValidationRule);
                    return rules;
                }, {});
            const rules: AbstractValidationRule[] = Object.values(fieldRules);
            return {
                rule: rules.length == 1 ? rules[0] : new AggregationRule(AggregationRule.Type.AND, rules),
                fieldRules
            }
        } else {
            throw Error("Invalid rule string: not a collection of field validation rules.");
        }
    } else {
        return {
            rule: parsed
        }
    }
}