import { ValidationContext } from "../../models/ValidationContext";
import { stringifyValue } from "../../utils/StringUtils";
import { AbstractValueRule } from "./AbstractValueRule";

function getFieldValue(object: any, fields: string[]): any {
    if (!fields.length) return object;
    const field = fields[0];
    if (object && typeof object == 'object') {
        if (field == "[]") {
            return Object.values(object).map((value: any) => getFieldValue(value, fields.slice(1)));
        } else {
            return getFieldValue(object[field], fields.slice(1));
        }
    } else {
        return undefined;
    }
}

function splitFields(fieldString = "") {
    const fields: string[] = []
    for (const field of fieldString.split(".")) {
        if (field.includes("[")) {
            const arrayFields = field.split("[");
            arrayFields.map(element => element == "]" ? "[]" : element.replace(/\]$/, ""));
            fields.push(...arrayFields);
        } else {
            fields.push(field);
        }
    }
    return fields;
}

enum Type {
    FIELD = "FIELD"
}

export class FieldRule extends AbstractValueRule {
    static Type = Type;

    static create(obj: { field: string, defaultValue: any }) {
        return new FieldRule(obj['field'], obj['defaultValue']);
    }

    type: Type = Type.FIELD;
    fieldName: string;
    defaultValue: any;
    
    constructor(fieldName: string, defaultValue?: any) {
        super();
        this.fieldName = fieldName;
        this.defaultValue = defaultValue;
    }

    getFieldName(context: ValidationContext) {
        return context.getFieldName(this.fieldName);
    }

    getValue(context: ValidationContext) {
        let value = null;
        if (!this.fieldName) { // use "_" insead of ""?
            value = getFieldValue(context.fieldValue, []);
		} else if (this.fieldName.startsWith("$.")) {
            value = getFieldValue(context.root, splitFields(this.fieldName.substring(2)));
		} else if (this.fieldName == "$") {
            value = getFieldValue(context.root, []);
		} else {
            value = getFieldValue(context.fieldValue, splitFields(this.fieldName));
		}
        console.log('LOGME FIELD_RULE VALUE:', value);
        if (value === null && this.defaultValue !== null) {
            return (this.defaultValue instanceof AbstractValueRule) ? this.defaultValue.getValue(context) : this.defaultValue;
        }
        return value;
    }

    toString() {
        const args = [`"${this.fieldName}"`];
        if (this.defaultValue !== undefined) args.push(stringifyValue(this.defaultValue));
        return `${this.type}(${args.join(", ")})`;
    }
}

